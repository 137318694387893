import { API_URL } from "@env";
import { Link } from "@react-navigation/native";
import { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, Animated, Picker, Pressable, StyleSheet, Text, TextInput, View, useWindowDimensions } from 'react-native';
import AntDesign from 'react-native-vector-icons/AntDesign';
import Entypo from 'react-native-vector-icons/Entypo';
import Octicon from 'react-native-vector-icons/Octicons';
import { Footer } from "./Footer";
import { Header } from "./Header";
import { ProfileContext } from "./ProfileContext";
import { ResponsiveView } from "./ResponsiveView";
import { getFieldsAsString, getLanguagesAsString } from "./Utils";
import { ProfilePicture } from './components/ProfilePicture';
import { Directory } from "./help/HelpBase";
import useLang from "./hooks/use-lang";


function SearchBar({ consultants, setConsultants, reset, category, setCategory, categoryType, setCategoryType }) {
    const { height, width } = useWindowDimensions()
    const [pickerStyles, setPickerStyles] = useState({ width: 150 })
    const [searchText, setSearchText] = useState("")

    const toggleCategory = () => {
        if (categoryType == "category") {
            setCategoryType("service")
            setCategory("_choose")
        } else {
            setCategoryType("category")
            setCategory("all")
        }
    }

    const search = async () => {
        if (searchText.trim() == '') {
            reset(category, categoryType)
            return
        }
        let params = new URLSearchParams({
            q: searchText
        })
        const request = await fetch(`${API_URL}/api/consultant/search?` + params)

        if (request.ok) {
            setConsultants(await request.json())
        }
    }

    useEffect(() => {
        if (width < 443) {
            setPickerStyles({ width: 70, fontSize: 12 })
        } else {
            setPickerStyles({ width: 150 })
        }
    }, [width])

    return (
        <View>
            <View style={{ flexDirection: 'row' }}>
                <TextInput style={styles.searchBar} placeholder='Search lawyers by name, occupation or area...' placeholderTextColor={"#b0b0b0"}
                    onSubmitEditing={search} value={searchText} onChangeText={(t) => setSearchText(t)} />
                <Pressable style={styles.searchButton} onPress={search}>
                    <AntDesign name="search1" size={20} color={"white"} />
                </Pressable>
            </View>
        </View>
    )
}

function Consultant({ consultant, navigation }) {
    const profileContext = useContext(ProfileContext)
    const [isHovered, setHover] = useState(false)
    const { height, width } = useWindowDimensions()

    const redirect = () => {
        //Linking.openURL(`/profile/${consultant.id}`, "_self")
        profileContext.setProfile(consultant)
        navigation.push("Profile", {
            userId: consultant.id
        })
    }

    return (
        <Link /*to={{ screen: "Profile", params: { userId: consultant.id } }}*/ to={`/profile/${consultant.id}`}>
            <View style={[styles.consultant, isHovered && styles.consultantHover]} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} dir="ltr">
                <View style={{ flexDirection: 'row', flex: 1 }}>
                    <ProfilePicture
                        isProfilePicture={consultant.profile_picture}
                        userId={consultant.user}
                        name={consultant.name}
                        style={width > 400 ? { borderRadius: 20, width: 80, height: 80 } : { borderRadius: 15, width: 65, height: 65 }}
                        textStyle={width < 400 ? { fontSize: 28 } : null}
                    />
                    <View style={{ marginLeft: 20, marginTop: 10, flex: 1 }}>
                        <Text style={styles.consultantName}>{consultant.name}</Text>
                        <Text style={{ color: 'gray', fontSize: 13, fontFamily: "Verdana, sans-serif" }}>{consultant.occupation}</Text>
                        <View style={{ marginBottom: 8 }} />

                        <View style={{ gap: 5 }}>
                            <View style={{ flexDirection: 'row' }}>
                                <Entypo name="briefcase" size={15} color={"gray"} style={{ marginRight: 10 }} />
                                <Text style={{ color: 'gray', fontFamily: "Poppins, sans-serif" }} numberOfLines={1}>{getFieldsAsString(consultant.specialized_fields)}</Text>
                            </View>
                            {consultant.spoken_languages.length > 0 ? (
                                <View style={{ flexDirection: 'row' }}>
                                    <Entypo name="language" size={15} color={"gray"} style={{ marginRight: 10 }} />
                                    <Text style={{ color: 'gray', fontFamily: "Poppins, sans-serif" }} numberOfLines={1}>{getLanguagesAsString(consultant.spoken_languages)}</Text>
                                </View>
                            ) : null}
                            <View style={{ flexDirection: 'row' }}>
                                <Entypo name="location-pin" size={15} color={"gray"} style={{ marginRight: 10 }} />
                                <Text style={{ color: 'gray', fontFamily: "Poppins, sans-serif" }} numberOfLines={1}>{consultant.area}</Text>
                            </View>
                            <View style={{ flexDirection: 'row' }}>
                                <AntDesign name="star" size={15} color={"gray"} style={{ marginRight: 10 }} />
                                <Text style={{ color: 'gray', fontFamily: "Poppins, sans-serif" }} numberOfLines={1}>{consultant.statistics["rating"] != 0 ? consultant.statistics["rating"] : "Not Yet Rated"}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <AntDesign name="right" size={30} color={"#d6d6d6"} style={{ alignSelf: 'center' }} />
            </View>
        </Link>
    )
}

function FilterSection({name, children, initialOpen}) {
    const [open, setOpen] = useState(initialOpen ? initialOpen : false)

    return (
        <View>
            <Pressable style={{flexDirection: 'row', justifyContent: 'space-between'}} onPress={() => setOpen(!open)}>
                <Text style={{fontFamily: "PoppinsMedium", fontSize: 14, userSelect: 'none'}}>
                    {name}
                </Text>
                <AntDesign name={open ? "minus" : "plus"} size={20} />
            </Pressable>
            {open ? (
                <View style={{marginLeft: 15, marginVertical: 8}}>
                    {children}
                </View>
            ) : null}
        </View>
    )
}

function Checkbox({selected, setSelected, label, value}) {
    return (
        <Pressable style={{flexDirection: 'row', gap: 8, alignItems: 'center', alignSelf: 'flex-start'}} onPress={() => setSelected(value)}>
            <View style={{padding: 1, borderRadius: 100, height: 15, width: 15, borderWidth: 1}}>
                {selected ? (
                    <View style={{flex: 1, borderRadius: 100, backgroundColor: '#192655'}} />
                ) : null}
            </View>
            <Text style={{userSelect: 'none'}}>{label}</Text>
        </Pressable>
    )
}

function FilterBox({ updateConsultants, initialSelectedField }) {
    const [collapsed, setCollapsed] = useState(initialSelectedField ? false : true)
    const [collapseHeight, setHeight] = useState(50)
    const [ignoreFirst, setIgnoreFirst] = useState(false)
    const [ignoreFirstService, setIgnoreFirstService] = useState(false)
    const [selectedS, setSelectedS] = useState(initialSelectedField)
    const [selectedService, setSelectedService] = useState("all")

    const setSelectedSWrapper = (category) => {
        setSelectedS(category)
        setSelectedService("all")
        updateConsultants(category, "category")
    }

    const setSelectedServiceWrapper = (service) => {
        setSelectedService(service)
        setSelectedS("all")
        updateConsultants(service, "service")
    }

    const open = () => {
        setCollapsed(false)
        setHeight(110)
    }

    const close = () => {
        setCollapsed(true)
        setHeight(50)
    }

    useEffect(() => {
        open()
    }, [])

    const handleFilterPress = () => {
        if (collapsed) {
            open()
        } else {
            close()
        }
    }

    return (
        <View style={[{borderColor: '#dedede', borderWidth: 1, borderRadius: 5, overflow: 'hidden'}, collapseHeight == 50 ? {height: collapseHeight} : {minHeight: collapseHeight}]}>
            <Pressable onPress={handleFilterPress}>
                <View style={{height: 50, justifyContent: 'center'}}>
                    <View style={{justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', padding: 10}}>
                        <Text style={{fontFamily: "PoppinsSemiBold", fontSize: 15, userSelect: 'none'}}>
                            Filter
                        </Text>
                        <Entypo name={collapsed ? "chevron-thin-down" : "chevron-thin-up"} size={15} color={"gray"} />
                    </View>
                </View>
            </Pressable>

            <View style={{marginHorizontal: 10, gap: 10, paddingBottom: 10}}>
                <FilterSection name="Specialization" initialOpen={initialSelectedField != "all"}>
                    <View style={{gap: 5}}>
                        <Checkbox selected={selectedS == "all"} setSelected={setSelectedSWrapper} label="All" value="all" />
                        <Checkbox selected={selectedS == "civil law"} setSelected={setSelectedSWrapper} label="Civil Law" value="civil law" />
                        <Checkbox selected={selectedS == "criminal law"} setSelected={setSelectedSWrapper} label="Criminal Law" value="criminal law" />
                        <Checkbox selected={selectedS == "family law"} setSelected={setSelectedSWrapper} label="Family Law" value="family law" />
                        <Checkbox selected={selectedS == "banking law"} setSelected={setSelectedSWrapper} label="Banking Law" value="banking law" />
                        <Checkbox selected={selectedS == "commercial law"} setSelected={setSelectedSWrapper} label="Commercial Law" value="commercial law" />
                        <Checkbox selected={selectedS == "labor law"} setSelected={setSelectedSWrapper} label="Labor Law" value="labor law" />
                        <Checkbox selected={selectedS == "private law"} setSelected={setSelectedSWrapper} label="Private Law" value="private law" />
                        <Checkbox selected={selectedS == "public law"} setSelected={setSelectedSWrapper} label="Public Law" value="public law" />
                    </View>
                </FilterSection>
                <FilterSection name="Legal Service">
                    <View style={{gap: 5}}>
                        <Checkbox selected={selectedService == "all"} setSelected={setSelectedServiceWrapper} label="All" value="all" />
                        <Checkbox selected={selectedService == "notarization"} setSelected={setSelectedServiceWrapper} label="Notarization" value="notarization" />
                        <Checkbox selected={selectedService == "ratification"} setSelected={setSelectedServiceWrapper} label="Ratification" value="ratification" />
                        <Checkbox selected={selectedService == "legal_representation"} setSelected={setSelectedServiceWrapper} label="Legal Representation" value="legal_representation" />
                        <Checkbox selected={selectedService == "mediation"} setSelected={setSelectedServiceWrapper} label="Mediation" value="mediation" />
                        <Checkbox selected={selectedService == "legal_advice"} setSelected={setSelectedServiceWrapper} label="Legal Advice" value="legal_advice" />
                    </View>
                </FilterSection>
            </View>
        </View>
    )
}

function Body({ lang, navigation, params }) {
    const {t} = useTranslation()
    const { height, width } = useWindowDimensions()
    const [loading, setLoading] = useState(false)
    const [consultants, setConsultants] = useState([])
    const [category, setCategory] = useState(params?.category ? params?.category : "all")
    const [categoryType, setCategoryType] = useState("category")

    const getConsultants = async (category, categoryType, area) => {
        let params = {
            "category": category,
            "filter_by": categoryType == "category" ? "law_field" : "service"
        }

        if (area) {
            params["area"] = area
        }

        var consultants = await fetch(`${API_URL}/api/consultant/list?` + new URLSearchParams(params))
        var response = await consultants.json()

        if ("error" in response) {
            throw response["error"]
        }

        return response
    }

    const updateConsultants = (category, categoryType) => {
        setLoading(true)
        getConsultants(category, categoryType).then(consultants => {
            setConsultants(consultants)
            setLoading(false)
        }).catch(error => {
            console.log("Error")
            setLoading(false)
        })
    }


    useEffect(() => {
        setLoading(true)
        updateConsultants(params?.category ? params?.category : "all", "category")
    }, [])

    return (
        <View>
            <Helmet>
                {lang == "ar" ? (
                    <link rel="alternate" hreflang="ar-bh" href="/ar/lawyers" />
                ) : null}
                <link rel="canonical" href={lang == "ar" ? "https://www.consultmebh.com/ar/lawyers" : "https://www.consultmebh.com/lawyers"} />
                <meta property="og:title" content={lang == "ar" ? "محامون البحرين - استشارات قانونية وخدمات قانونية متميزة" : "Bahrain Lawyers - Leading Attorneys in Bahrain"} />
                <meta property="og:description" content={lang == "ar" ? "تواصل مع نخبة من المحامين البحرينيين من مختلف التخصصات واحصل على جميع الخدمات القانونية من توثيق خاص وتصديق ووساطة وتأسيس الشركات والتمثيل القانوني والاستشارات القانونية عبر تطبيق استشرني." : "Choose from the leading Bahrain lawyers and get access to a range of legal services & consultations."} />
                <meta name="description" content={lang == "ar" ? "تواصل مع نخبة من المحامين البحرينيين من مختلف التخصصات واحصل على جميع الخدمات القانونية من توثيق خاص وتصديق ووساطة وتأسيس الشركات والتمثيل القانوني والاستشارات القانونية عبر تطبيق استشرني." : "Choose from the leading Bahrain lawyers and get access to a range of legal services & consultations."} />
            </Helmet>
            <View accessibilityRole="main" style={{ alignItems: 'center', marginBottom: 55, minHeight: '100%' }}>
                <ResponsiveView customWidth={1200}>
                    <Directory title={t("header.consultants")} />
                    <Text accessibilityRole="heading" style={{marginTop: 20, fontFamily: "PoppinsMedium", fontSize: 26, color: 'rgb(15, 23, 42)'}}>{t("lawyers.h1")}</Text>
                    <View style={{flexDirection: width > 700 ? 'row' : 'column', marginTop: 50, gap: 20}}>
                        <View style={width > 700 ? {flex: 1} : null}>
                            <FilterBox updateConsultants={updateConsultants} initialSelectedField={category} />
                        </View>
                        <View style={width > 700 ? {flex: 3} : null}>
                            <View style={{marginBottom: 50}}>
                                <SearchBar consultants={consultants} setConsultants={setConsultants} reset={updateConsultants}
                                        category={category} setCategory={setCategory} categoryType={categoryType} setCategoryType={setCategoryType}    />
                            </View>
                            <View>
                                {loading ? (
                                    <View style={{alignItems: 'center', marginTop: 10}}>
                                        <ActivityIndicator size="large" />
                                    </View>
                                ) : (
                                    <>
                                        {consultants.length > 0 ? (
                                            <>
                                                {consultants.map(consultant =>
                                                    <Consultant
                                                        key={consultant["id"]}
                                                        consultant={consultant}
        
                                                        navigation={navigation}
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            <Text>No results found.</Text>
                                        )}
                                    </>
                                )}
                            </View>
                        </View>
                    </View>
                </ResponsiveView>
            </View>
            <Footer />
        </View>
    )
}

export function Consultants({ navigation, route }) {
    const {t, i18n} = useTranslation()
    const ready = useLang()

    if (!ready) {
        return null
    }

    return (
        <View style={{ flex: 1, backgroundColor: 'white' }}>
            <Header />
            <Body params={route.params} lang={route.name == "ConsultantsAr" ? "ar" : "en"} navigation={navigation} />
        </View>
    )
}

const styles = StyleSheet.create({
    dropdown: {
        borderColor: '#dedede',
        borderRightWidth: 0,
        borderRadius: 5,
        width: 150,
        backgroundColor: 'white',
        borderStyle: 'solid',
        borderWidth: 1,
        fontSize: 14
    },
    searchBar: {
        borderWidth: 1,
        borderColor: '#dedede',
        borderRightWidth: 0,
        paddingHorizontal: 5,
        paddingLeft: 15,
        paddingVertical: 15,
        outlineStyle: 'none',
        fontSize: 15,
        flex: 1,
        minWidth: 0,
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5
    },
    searchButton: {
        alignItems: 'center',
        justifyContent: 'center',
        width: 50,
        backgroundColor: '#192655',
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
    },
    consultant: {
        minHeight: 200,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#dedede',
        padding: 20,
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%'
    },
    consultantHover: {
        shadowColor: "#000000",
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.17,
        shadowRadius: 10,
    },
    consultantName: {
        fontSize: 15,
        fontFamily: "Verdana, sans-serif",
        color: '#2d2d2d',
        letterSpacing: 0.5,
    },
})