import { View, StyleSheet, Pressable, useWindowDimensions } from "react-native"
import { Text } from "../components/Text"
import { ResponsiveView } from "../ResponsiveView"
import { Header } from "../Header"
import { Link } from "@react-navigation/native"
import { MobileDownloadModal } from "../components/MobileDownloadModal"
import Entypo from "react-native-vector-icons/Entypo"
import { useState } from "react"
import { Footer } from "../Footer"
import { useTranslation } from "react-i18next"

export function Directory({title}) {
    const {t, i18n} = useTranslation()

    return (
        <View style={{flexDirection: i18n.language == "ar" ? 'row-reverse' : 'row', marginTop: 30, gap: 8, alignItems: 'center', alignSelf: i18n.language == "ar" ? 'flex-end' : 'flex-start'}}>
            <Link to={{screen: "Home"}} style={{fontSize: 12.5, fontFamily: "sans-serif"}}>
                {t("articlesMeta.Home")}
            </Link>
            <Entypo name={i18n.language == "ar" ? "chevron-thin-left" : "chevron-thin-right"} size={10} color={"gray"} />
            <Text style={{fontSize: 12.5, fontFamily: "sans-serif", color: '#969696'}}>
                {title}
            </Text>
        </View>
    )
}

function DownloadBox() {
    const [modalVisible, setModalVisible] = useState(false)
    const {height, width} = useWindowDimensions()
    const {t, i18n} = useTranslation()

    let margin = i18n.language == "ar" ? {marginRight: width > 1000 ? 20 : 0} : {marginLeft: width > 1000 ? 20 : 0}

    return (
        <View style={[{padding: 15, borderWidth: 1, borderColor: '#e6e6e6', borderRadius: 10}, margin]}>
            <MobileDownloadModal suppressLogin visible={modalVisible} setVisible={setModalVisible} />
            <Text style={styles.downloadText}>
                {t("articlesMeta.downloadDesc")}
            </Text>
            <Pressable style={styles.downloadButton} onPress={() => setModalVisible(true)}>
                <Text style={styles.downloadButtonText}>{t("articlesMeta.download")}</Text>
            </Pressable>
        </View>
    )
}

export function HelpBaseView({children, title}) {
    const {height, width} = useWindowDimensions()
    const {i18n} = useTranslation()

    let row = i18n.language == "ar" ? 'row-reverse' : 'row'
    
    return (
        <View style={[styles.container]}>
            <Header />
            <View style={{alignItems: 'center', flex: 1, minHeight: height}} accessibilityRole="article">
                <ResponsiveView>
                    <Directory title={title} />
                    <View style={{flexDirection: width > 1000 ? row : 'column', marginTop: width > 1000 ? 40 : 0}}>
                        {width > 1000 ? (
                            <>                            
                                <View style={{flex: 20}}>
                                    {children}
                                    <View style={{marginBottom: 130}} />
                                </View>
                                <View style={{flex: 10}}>
                                    <DownloadBox />
                                </View>
                            </>
                        ) : (
                            <>                            
                                <View style={{marginVertical: 30}}>
                                    <DownloadBox />
                                </View>
                                <View style={{flex: 20, marginHorizontal: 15}}>
                                    {children}
                                    <View style={{marginBottom: 130}} />
                                </View>
                            </>
                        )}
                    </View>
                </ResponsiveView>
            </View>
            <Footer />
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white'
    },
    downloadText: {
        fontFamily: "Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif",
        fontSize: 16,
        fontWeight: '400',
        marginBottom: 20
    },
    downloadButton: {
        alignItems: 'center',
        paddingHorizontal: 10,
        paddingVertical: 7,
        backgroundColor: '#192655',
        borderRadius: 8
    },
    downloadButtonText: {
        color: 'white',
        fontFamily: "sans-serif",
        fontWeight: '600',
        letterSpacing: 0.5,
    }
})