import 'react-native-gesture-handler'
import './i18n'
import { useTranslation } from 'react-i18next'
import { Text, View, Image } from "react-native"
import { Home } from "./Home"
import { Profile } from './Profile'
import { Consultants } from './Consultants'
import { GettingStarted } from './help/GettingStarted'
import { Contact } from './help/Contact'
import { BecomeConsultant } from './help/BecomeConsultant'
import { TermsOfService } from './help/TermsOfService'
import { NavigationContainer, useRoute } from "@react-navigation/native"
import { createStackNavigator } from "@react-navigation/stack"
import { useFonts } from 'expo-font'
import { ProfileProvider } from './ProfileContext'
import { useContext, useEffect } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { AccountDeletion } from './help/AccountDeletion'
import { PrivacyPolicy } from './help/PrivacyPolicy'
import { Header } from './Header'
import { AuthContext, AuthProvider } from './contexts/AuthContext'
import { SignUp } from './SignUp'
import { API_URL } from "@env"
import { Login } from './Login'
import { Form } from './SubmitForm'

const Stack = createStackNavigator()

function NotFound() {
  return (
    <View style={{ flex: 1 }}>
      <Header />
      <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
        <View style={{ backgroundColor: 'white', padding: 25, borderRadius: 15 }}>
          <Text style={{ fontFamily: "PoppinsSemiBold", fontSize: 21 }}>404 | Not Found</Text>
          <Text>The page you were looking for could not be found.</Text>
        </View>
      </View>
    </View>
  )
}

function Main() {
  const { t, i18n } = useTranslation()
  const authContext = useContext(AuthContext)
  const [fontsLoaded] = useFonts({
    PoppinsBold: require('./assets/fonts/Poppins-Bold.ttf'),
    PoppinsSemiBold: require('./assets/fonts/Poppins-SemiBold.ttf'),
    PoppinsLight: require('./assets/fonts/Poppins-Light.ttf'),
    PoppinsMedium: require('./assets/fonts/Poppins-Medium.ttf'),
    PoppinsRegular: require('./assets/fonts/Poppins-Regular.ttf'),
    NotoSansBold: require('./assets/fonts/NotoSansArabic-Bold.ttf'),
    NotoSansLight: require('./assets/fonts/NotoSansArabic-Light.ttf'),
    NotoSansMedium: require('./assets/fonts/NotoSansArabic-Medium.ttf'),
    NotoSansRegular: require('./assets/fonts/NotoSansArabic-Regular.ttf'),
    NotoSansSemiBold: require('./assets/fonts/NotoSansArabic-SemiBold.ttf'),
    Inter: require('./assets/fonts/Inter.ttf')
  })

  const getToken = async () => {
    return await AsyncStorage.getItem("token")
  }

  const getAuthenticated = async (token) => {
    let authenticated = await fetch(`${API_URL}/api/authenticated`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (authenticated.ok) {
      return await authenticated.json()
    }
  }

  useEffect(() => {
    getToken().then(token => {
      if (token) {
        getAuthenticated(token).then(user => {
          authContext.setUser(user)
          authContext.setToken(token)
          authContext.setLoading(false)
        })
      } else {
        authContext.setLoading(false)
      }
    })
  }, [])

  const config = {
    screens: {
      // en or general
      Profile: {
        path: 'profile/:userId'
      },
      Form: {
        path: 'profile/:consultantId/form'
      },
      Home: '/',
      SignUp: 'sign-up',
      Login: 'login',
      Consultants: 'lawyers',
      Help_GettingStarted: 'help/getting-started',
      Help_Contact: 'help/contact',
      Help_BecomeConsultant: 'help/become-a-consultant',
      Help_AccountDeletion: "help/account-deletion",
      Help_TermsOfService: "terms-of-service",
      Help_PrivacyPolicy: "privacy-policy",
      NotFound: '*',
      
      
      // arabic
      ProfileAr: {
        path: '/ar/profile/:userId'
      },
      HomeAr: '/ar',
      SignUpAr: '/ar/sign-up',
      LoginAr: '/ar/login',
      ConsultantsAr: '/ar/lawyers',
      Help_GettingStartedAr: '/ar/help/getting-started',
      Help_ContactAr: '/ar/help/contact',
      Help_BecomeConsultantAr: '/ar/help/become-a-consultant',
      Help_AccountDeletionAr: "/ar/help/account-deletion",
      Help_TermsOfServiceAr: "/ar/terms-of-service",
      Help_PrivacyPolicyAr: "/ar/privacy-policy",
    },
  }

  const linking = {
    config
  }

  if (authContext.loading) {
    return (
      <View />
    )
  }

  return (
    <View style={{ flex: 1 }}>
      <NavigationContainer linking={linking}>
        <Stack.Navigator screenOptions={{ headerShown: false }}>
          <Stack.Group>
            <Stack.Screen name="Home" component={Home} options={{ title: "Consult Me" }} />
            <Stack.Screen name="Login" component={Login} options={{ title: "Log in - Consult Me" }} />
            <Stack.Screen name="SignUp" component={SignUp} options={{ title: "Sign up - Consult Me" }} />
            <Stack.Screen name="Profile" component={Profile} />
            <Stack.Screen name="Form" component={Form} />
            <Stack.Screen name="Consultants" component={Consultants} options={{ title: "Bahrain Lawyers - Leading Attorneys in Bahrain" }} />
            <Stack.Screen name="Help_GettingStarted" component={GettingStarted} options={{ title: "Getting Started" }} />
            <Stack.Screen name="Help_Contact" component={Contact} options={{ title: "Contact" }} />
            <Stack.Screen name="Help_BecomeConsultant" component={BecomeConsultant} options={{ title: "Become a consultant" }} />
            <Stack.Screen name="Help_TermsOfService" component={TermsOfService} options={{ title: "Terms of Service" }} />
            <Stack.Screen name="Help_PrivacyPolicy" component={PrivacyPolicy} options={{ title: "Privacy Policy" }} />
            <Stack.Screen name="Help_AccountDeletion" component={AccountDeletion} options={{ title: "Account Deletion" }} />
            <Stack.Screen name="NotFound" component={NotFound} options={{ title: "404 | Not Found" }} />
          </Stack.Group>

          <Stack.Group>
            <Stack.Screen name="HomeAr" component={Home} options={{ title: "استشرني" }} />
            <Stack.Screen name="LoginAr" component={Login} options={{ title: "تسجيل الدخول - استشرني" }} />
            <Stack.Screen name="SignUpAr" component={SignUp} options={{ title: "اشتراك - استشرني" }} />
            <Stack.Screen name="ProfileAr" component={Profile} />
            <Stack.Screen name="Help_GettingStartedAr" component={GettingStarted} options={{ title: "ابدء" }} />
            <Stack.Screen name="Help_ContactAr" component={Contact} options={{ title: "اتصال" }} />
            <Stack.Screen name="Help_BecomeConsultantAr" component={BecomeConsultant} options={{ title: "كن مستشارًا" }} />
            <Stack.Screen name="Help_TermsOfServiceAr" component={TermsOfService} options={{ title: "شروط الخدمة" }} />
            <Stack.Screen name="Help_PrivacyPolicyAr" component={PrivacyPolicy} options={{ title: "سياسة الخصوصية" }} />
            <Stack.Screen name="Help_AccountDeletionAr" component={AccountDeletion} options={{ title: "حذف الحساب" }} />
            <Stack.Screen name="ConsultantsAr" component={Consultants} options={{ title: "محامون البحرين - استشارات قانونية وخدمات قانونية متميزة" }} />
          </Stack.Group>
        </Stack.Navigator>
      </NavigationContainer>
    </View>
  )
}

export default function App() {
  return (
    <AuthProvider>
      <ProfileProvider>
        <Main />
      </ProfileProvider>
    </AuthProvider>
  )
}