import { View, StyleSheet, Linking } from "react-native"
import { Text } from "../components/Text"
import { HelpBaseView } from "./HelpBase"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet"
import Feather from 'react-native-vector-icons/Feather'
import useLang from "../hooks/use-lang"

export function Contact() {
    const {t} = useTranslation()
    const isReady = useLang()

    if (!isReady) {
        return null
    }

    return (
        <HelpBaseView title={t("articlesMeta.contact")}>
            <Helmet>
                <meta property="og:title" content={"Contact Us"} />
                <meta property="og:description" content={t("contactUs.p1")} />
                <meta name="description" content={t("contactUs.p1")} />
            </Helmet>
            <Text style={{color: 'gray', fontWeight: 'bold', letterSpacing: 0.5, marginBottom: 10}}>{t("articlesMeta.contact")}</Text>
            <Text style={styles.headerText} accessibilityRole="heading">{t("contactUs.title")}</Text>
            <View style={{flexDirection: 'row', gap: 5, alignItems: 'center'}}>
                <Text style={styles.regularText}>
                    {t("contactUs.p1")}
                </Text>
                <Feather name="external-link" color={"rgb(0, 54, 246)"} size={15} onPress={() => Linking.openURL("mailto:help@consultmebh.com")} />
            </View>
            <View style={{marginBottom: 600}} />
        </HelpBaseView>
    )
}

const styles = StyleSheet.create({
    headerText: {
        fontFamily: "sans-serif, NotoSansMedium",
        fontSize: 30,
        fontWeight: '600',
        marginBottom: 16
    },
    subHeaderText: {
        fontFamily: "sans-serif, NotoSansRegular",
        fontSize: 22,
        fontWeight: '500',
        marginTop: 25,
        marginBottom: 12
    },
    regularText: {
        fontSize: 15.5,
        marginVertical: 4,
        letterSpacing: 0.2
    }
})