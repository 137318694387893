import React, {useState} from "react"

const ProfileContext = React.createContext()

const ProfileProvider = ({children}) => {
    const [currentProfile, setCurrentProfile] = useState({})

    const dashboardContext = {
        profile: currentProfile,
        setProfile: setCurrentProfile
    }

    return (
        <ProfileContext.Provider value={dashboardContext}>
            {children}
        </ProfileContext.Provider>
    )
}

export {ProfileContext, ProfileProvider}