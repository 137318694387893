import { View, StyleSheet } from "react-native"
import { Text } from "../components/Text"
import { HelpBaseView } from "./HelpBase"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet"
import useLang from "../hooks/use-lang"

export function GettingStarted() {
    const { t } = useTranslation()
    const isReady = useLang()

    if (!isReady) {
        return null
    }

    return (
        <HelpBaseView title={t("articlesMeta.gettingStarted")}>
            <Helmet>
                <meta property="og:title" content={"Getting Started"} />
                <meta property="og:description" content={t("gettingStarted.p1")} />
                <meta name="description" content={t("gettingStarted.p1")} />
            </Helmet>
            <Text style={{ color: 'gray', fontWeight: 'bold', letterSpacing: 0.5, marginBottom: 10 }}>{t("articlesMeta.guide")}</Text>
            <Text accessibilityRole="heading" accessibilityLevel={1} style={styles.headerText}>{t("gettingStarted.title")}</Text>
            <Text style={styles.regularText}>
                {t("gettingStarted.p1")}
            </Text>
            <Text accessibilityRole="heading" accessibilityLevel={2} style={styles.subHeaderText}>
                {t("gettingStarted.h1")}
            </Text>
            <Text style={styles.regularText}>
                {t("gettingStarted.p2")}
            </Text>
            <Text style={styles.regularText}>
                {t("gettingStarted.p3")}
            </Text>
            <Text accessibilityRole="heading" accessibilityLevel={2} style={styles.subHeaderText}>
                {t("gettingStarted.h2")}
            </Text>
            <Text style={styles.regularText}>
                {t("gettingStarted.p4")}
            </Text>
            <Text style={styles.regularText}>
                {t("gettingStarted.p5")}
            </Text>
            <Text style={styles.subHeaderText}>
                {t("gettingStarted.h3")}
            </Text>
            <Text style={styles.regularText}>
                {t("gettingStarted.p6")}
            </Text>
            <Text style={styles.regularText}>
                {t("gettingStarted.p7")}
            </Text>
            <Text accessibilityRole="heading" accessibilityLevel={2} style={styles.subHeaderText}>
                {t("gettingStarted.h4")}
            </Text>
            <Text style={styles.regularText}>
                {t("gettingStarted.p8")}
            </Text>
        </HelpBaseView>
    )
}

const styles = StyleSheet.create({
    headerText: {
        fontFamily: "sans-serif, NotoSansMedium",
        fontSize: 30,
        fontWeight: '600',
        marginBottom: 16
    },
    subHeaderText: {
        fontFamily: "sans-serif, NotoSansRegular",
        fontSize: 22,
        fontWeight: '500',
        marginTop: 25,
        marginBottom: 12
    },
    regularText: {
        fontSize: 15.5,
        marginVertical: 4,
        letterSpacing: 0.2
    }
})