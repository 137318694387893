export const serviceMap = {
    consultation: "Consultation",
    notarization: "Notarization",
    ratification: "Ratification",
    legal_representation: "Legal Representation",
    mediation: "Mediation",
    legal_advice: "Legal Advice"
}

export const lawFieldMap = {
    "civil law": "Civil Law",
    "criminal law": "Criminal Law",
    "family law": "Family Law",
    "banking law": "Banking Law",
    "commercial law": "Commercial Law",
    "labor law": "Labor Law",
    "private law": "Private Law",
    "public law": "Public Law"
}

export const languageMap = {
    "arabic": "Arabic",
    "english": "English"
}