import {View, Pressable, Image, useWindowDimensions, Linking} from "react-native"
import { Text } from "./components/Text"
import { ResponsiveView } from "./ResponsiveView"
import AntDesign from 'react-native-vector-icons/AntDesign'
import { useNavigation } from "@react-navigation/native"
import { useTranslation } from "react-i18next"
import { Link } from "@react-navigation/native"

function CopyrightDisclaimer() {
    const {t} = useTranslation()

    return (
        <View style={{ paddingVertical: 30, borderTopWidth: 2, borderTopColor: 'rgb(232, 232, 232)' }}>
            <Text style={{ textAlign: 'center', fontFamily: "PoppinsLight, NotoSansLight" }}>{t("footer.copyright")}</Text>
        </View>
    )
}


function FooterItem({text, to}) {
    const {height, width} = useWindowDimensions()

    return (
      <Link to={to} style={{fontFamily: "PoppinsLight, NotoSansLight", fontSize: width > 600 ? 14 : 12}}>
        {text}
      </Link>
    )
  }

export function Footer() {
    const navigation = useNavigation()
    const {t, i18n} = useTranslation()
    const {height, width} = useWindowDimensions()

    let row = i18n.language == "ar" ? "row-reverse" : "row";
    let titleAlignment = i18n.language == "ar" ? 'right' : 'left';

    return (
        <View accessibilityRole="contentinfo" style={{ borderTopWidth: 1, borderTopColor: '#d6d6d6', alignItems: 'center', backgroundColor: 'white' }}>
            <ResponsiveView style={{ marginTop: 100 }}>
                <Text style={{ fontSize: 40, fontFamily: "PoppinsSemiBold, NotoSansSemiBold", textAlign: width < 768 ? 'center' : titleAlignment, marginBottom: width < 768 ? 25 : 0 }}>{t("footer.title")}</Text>
                <View style={{ flexDirection: width > 768 ? row : 'column', gap: width < 768 ? 25 : 0, justifyContent: 'space-between', alignItems: 'center' }}>
                    <Text style={{ fontFamily: "PoppinsLight, NotoSansLight", textAlign: width < 768 ? 'center' : 'auto' }}>
                        {t("footer.description")}
                    </Text>
                    <Link to={"/help/contact"}>
                        <View style={{ backgroundColor: '#192655', paddingHorizontal: 30, paddingVertical: 15, borderRadius: 100 }}>
                            <Text style={{ color: 'white', fontFamily: "PoppinsRegular, NotoSansRegular" }}>{t("footer.contactUs")}</Text>
                        </View>
                    </Link>
                </View>
                <View style={{ marginVertical: 40, flexDirection: width > 768 ? 'row' : 'column', gap: width < 768 ? 25 : 0, alignItems: 'center', justifyContent: 'space-between' }}>
                    <Image
                        source={require('./assets/icon.webp')}
                        style={{ width: 60, height: 60 }}
                    />
                    <View style={{ flexDirection: 'row', gap: 20 }}>
                        <FooterItem text={t("header.consultants")} to={`${i18n.language == "ar" ? "/ar" : ""}/lawyers`} />
                        <FooterItem text={t("footer.links.contactUs")} to={`${i18n.language == "ar" ? "/ar" : ""}/help/contact`} />
                        <FooterItem text={t("footer.links.becomeConsultant")} to={`${i18n.language == "ar" ? "/ar" : ""}/help/become-a-consultant`} />
                    </View>
                    <View style={{ flexDirection: 'row', gap: 20 }}>
                        <Pressable onPress={() => Linking.openURL("https://twitter.com/ConsultMeBH", "_blank")}>
                            <Link to={"https://twitter.com/ConsultMeBH"}>
                                <AntDesign name="twitter" size={15} />
                            </Link>
                        </Pressable>
                        <Pressable onPress={() => Linking.openURL("https://www.instagram.com/consultmebh/", "_blank")}>
                            <Link to={"https://www.instagram.com/consultmebh/"}>
                                <AntDesign name="instagram" size={15} />
                            </Link>
                        </Pressable>
                    </View>
                </View>
                <CopyrightDisclaimer />
            </ResponsiveView>
        </View>
    )
}