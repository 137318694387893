import { API_URL } from "@env"
import { useContext, useEffect, useState } from "react"
import { ActivityIndicator, Modal, Pressable, StyleSheet, Text, TextInput, View } from "react-native"
import AntDesign from 'react-native-vector-icons/AntDesign'
import { Header } from "./Header"
import { ResponsiveView } from "./ResponsiveView"
import { FormSuccessModal } from "./components/FormSuccessModal"
import { AuthContext } from "./contexts/AuthContext"

function Input({ style, ...props }) {
    return (
        <TextInput style={[styles.input, style]} {...props} />
    )
}

function DisabledInput({...props}) {
    return (
        <Input disabled style={[styles.input, {backgroundColor: '#f9fafb'}]} {...props} />
    )
}

function LoadingModal() {
    return (
        <Modal transparent visible>
            <View style={{flex: 1, backgroundColor: 'rgba(0, 0, 0, 0.3)', alignItems: 'center', justifyContent: 'center'}}>
                <View style={{padding: 10, borderRadius: 10, backgroundColor: 'white'}}>
                    <ActivityIndicator size={"large"} />
                </View>
            </View>
        </Modal>
    )
}

export function Form({navigation, route}) {
    const authContext = useContext(AuthContext)

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [subject, setSubject] = useState("")
    const [description, setDescription] = useState("")

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const params = route.params

    const createConversation = async () => {
        var req = await fetch(`${API_URL}/api/chat/create`, {
            method: 'POST',
            body: JSON.stringify({ "user": params.userId }),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authContext.token
            }
        })
        return req.ok
    }

    const submitForm = async () => {
        setLoading(true)

        let formData = new FormData()

        formData.append("subject", subject)
        formData.append("description", description)

        const response = await fetch(`${API_URL}/api/consultant/${params.consultantId}/send-form`, {
            method: "POST",
            body: formData,
            headers: {
                "Authorization": "Bearer " + authContext.token,
                "Accept": "application/json"
            }
        })

        if (response.ok) {
            createConversation()
            setSuccess(true)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!authContext.token) {
            navigation.push("Home")
            return
        }
        navigation.setOptions({
            title: params.name + "'s Form"
        })

        const user = authContext["user"]
        const userInfo = user["user_info"]

        if (user.user_info.middle_name) {
            setName(userInfo["first_name"] + ' ' + userInfo["middle_name"] + ' ' + userInfo["last_name"])
        } else {
            setName(userInfo["first_name"] + ' ' + userInfo["last_name"])
        }

        if (user["email"]) {
            setEmail(user["email"])
        } else {
            setEmail("N/A")
        }

        if (user["phone_number"]) {
            setPhoneNumber(`+${user["phone_number"]["country_code"]} ${user["phone_number"]["number"]}`)
        } else {
            setPhoneNumber("N/A")
        }
    }, [authContext.token])

    return (
        <View style={styles.container}>
            {loading ? (
                <LoadingModal />
            ) : null}
            <FormSuccessModal visible={success} setVisible={setSuccess} />
            <Header />
            <View style={{ alignItems: 'center' }}>
                <ResponsiveView customWidth={700}>
                    {navigation.canGoBack() ? (
                        <AntDesign name="arrowleft" size={35} style={{ marginTop: 25, alignSelf: 'flex-start' }} onPress={() => navigation.goBack()} />
                    ) : null}
                    <Text style={{ fontFamily: "Inter", fontWeight: '500', color: '#282a35', fontSize: 18, marginTop: 35 }}>
                        Name
                    </Text>
                    <DisabledInput value={name} />
                    <Text style={{ fontFamily: "Inter", fontWeight: '500', color: '#282a35', fontSize: 18, marginTop: 35 }}>
                        Email Address
                    </Text>
                    <DisabledInput value={email} />
                    <Text style={{ fontFamily: "Inter", fontWeight: '500', color: '#282a35', fontSize: 18, marginTop: 35 }}>
                        Phone Number
                    </Text>
                    <DisabledInput value={phoneNumber} />
                    <Text style={{ fontFamily: "Inter", fontWeight: '500', color: '#282a35', fontSize: 18, marginTop: 35 }}>
                        Subject
                    </Text>
                    <Input value={subject} onChangeText={(t) => setSubject(t)} />

                    <Text style={{ fontFamily: "Inter", fontWeight: '500', color: '#282a35', fontSize: 18, marginTop: 35 }}>
                        Description
                    </Text>
                    <Input value={description} onChangeText={(t) => setDescription(t)} multiline style={{minHeight: 250}} />

                    <Pressable onPress={submitForm} style={{borderRadius: 5, padding: 15, marginTop: 50, alignItems: 'center', backgroundColor: '#192655'}}>
                        <Text style={{color: 'white', fontFamily: "PoppinsMedium", fontSize: 17}}>
                            Book Appointment
                        </Text>
                    </Pressable>

                    <View style={{marginTop: 250}} />
                </ResponsiveView>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    input: {
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#9ca3af',
        height: 50,
        marginTop: 10,
        fontSize: 14,
        paddingVertical: 6,
        paddingHorizontal: 6,
        fontFamily: "PoppinsRegular",
        spellCheck: false,
        backgroundColor: 'white',
        outlineStyle: 'none'
    }
})