import { Linking, StyleSheet, Text, View } from "react-native";

function Fragment({children}) {
    return (
        <>
            {children}
        </>
    )
}

export function MixedFontText({ style, englishStyle, arabicStyle, allowLinks, ...props }) {
    let split = props.children.split(' ')
    let pattern = /[\u0600-\u06FF]/;

    const isURL = (text) => {
        if ((text.startsWith("https://") || text.startsWith("http://")) && text.includes('.')) {
            return true
        }
        return false
    }

    const isArabic = (text) => {
        return pattern.test(text)
    }

    return (
        <Text style={[style, {textAlign: isArabic(split[0]) ? 'right' : 'auto'}]} {...props}>
            {
                split.map((part, index) => {
                    if (allowLinks && isURL(part)) {
                        return (
                            <Fragment key={index}>
                                <Text onPress={() => Linking.openURL(part)} style={{textDecorationLine: 'underline'}}>{part}</Text>
                                {index + 1 != split.length && <Text>{" "}</Text>}
                            </Fragment>
                        )
                    } else {
                        return <Text key={index} style={isArabic(part) ? arabicStyle : englishStyle}>{part}{(index + 1 == split.length) ? "" : " "}</Text>
                    }
                })
            }
        </Text>
    )
}