import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import en from './langs/en.json'
import ar from './langs/ar.json'

const resources = {
    en: {translation: en},
    ar: {translation: ar}
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "en",

        interpolation: {
            escapeValue: false
        }
})

export default i18n