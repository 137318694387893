import { View, StyleSheet } from "react-native"
import { Text } from "../components/Text"
import { HelpBaseView } from "./HelpBase"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet"
import useLang from "../hooks/use-lang"

export function AccountDeletion() {
    const isReady = useLang()
    const { t } = useTranslation()

    if (!isReady) {
        return null
    }

    return (
        <HelpBaseView title={"Account Deletion"}>
            <Helmet>
                <meta property="og:title" content={"Account Deletion"} />
                <meta property="og:description" content={"Required steps to delete all data."} />
                <meta name="description" content={"Required steps to delete all data."} />
            </Helmet>
            <Text style={{ color: 'gray', fontWeight: 'bold', letterSpacing: 0.5, marginBottom: 10 }}>{t("articlesMeta.guide")}</Text>
            <Text style={styles.headerText}>Account Deletion</Text>
            <Text style={styles.regularText}>
                To request the account deletion of all of your data from Consult Me, please send us an email at help@consultmebh.com with the subject 'Account Removal Request' using the email address
                that you've registered with.
            </Text>
            <Text style={styles.regularText}>
                An account deletion will result in the deletion of all of your data including, but not limited to:
            </Text>
            <Text style={styles.regularText}>
                - Your name, profile picture, email address, and phone number.
            </Text>
            <Text style={styles.regularText}>
                - Your chat messages and conversations (the receipents messages will be deleted as well).
            </Text>
            <Text style={styles.regularText}>
                - If you are a consultant, your forms, cases, appointments history, invoices, etc will all be deleted. The original documents provided in the application process will be removed as well.
            </Text>
        </HelpBaseView>
    )
}

const styles = StyleSheet.create({
    headerText: {
        fontFamily: "sans-serif, NotoSansMedium",
        fontSize: 30,
        fontWeight: '600',
        marginBottom: 16
    },
    subHeaderText: {
        fontFamily: "sans-serif, NotoSansRegular",
        fontSize: 22,
        fontWeight: '500',
        marginTop: 25,
        marginBottom: 12
    },
    regularText: {
        fontSize: 15.5,
        marginVertical: 4,
        letterSpacing: 0.2
    }
})