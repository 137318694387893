import { API_URL } from "@env"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { useNavigation } from "@react-navigation/native"
import { useContext, useState } from "react"
import { Linking, Pressable, StyleSheet, Text, TextInput, View, useWindowDimensions } from "react-native"
import Entypo from "react-native-vector-icons/Entypo"
import { Header } from "./Header"
import { AuthContext } from "./contexts/AuthContext"
import useLang from "./hooks/use-lang"

function Input({ style, ...props }) {
    return (
        <TextInput style={[styles.input, style]} {...props} />
    )
}

function FullNameInput({firstName, setFirstName, lastName, setLastName, error}) {
    return (
        <View style={{ marginTop: 35 }}>
            <View style={{flexDirection: 'row', gap: 10}}>
                <View style={{flex: 1}}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Text style={{ fontFamily: "Inter", fontWeight: '500', color: '#282a35', fontSize: 16 }}>
                            First Name
                        </Text>
                    </View>
                    <Input style={(error && firstName == '') ? {borderColor: 'red'} : null} value={firstName} onChangeText={(t) => setFirstName(t)} />
                </View>
                <View style={{flex: 1}}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Text style={{ fontFamily: "Inter", fontWeight: '500', color: '#282a35', fontSize: 16 }}>
                            Last Name
                        </Text>
                    </View>
                    <Input style={(error && lastName == '') ? {borderColor: 'red'} : null} value={lastName} onChangeText={(t) => setLastName(t)} />
                </View>
            </View>
            {error ? (
                <Text style={{fontFamily: "sans-serif", fontSize: 12, color: 'red', marginTop: 2}}>
                    Please provide the required fields.
                </Text>
            ) : null}
        </View>
    )
}

function EmailInput({email, setEmail, error}) {
    const navigation = useNavigation()

    return (
        <View style={{ marginTop: 35 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Text style={{ fontFamily: "Inter", fontWeight: '500', color: '#282a35', fontSize: 16 }}>
                    Email
                </Text>
                <Text style={{ fontSize: 12, fontFamily: "PoppinsLight" }}>
                    Don't have an account? <Text onPress={() => navigation.push('SignUp')} style={{ fontFamily: "PoppinsSemiBold", color: 'rgb(26, 53, 105)', marginLeft: 5 }}>Sign Up</Text>
                </Text>
            </View>
            <Input style={error ? {borderColor: 'red'} : null} value={email} onChangeText={(t) => setEmail(t)} />
            {error ? (
                <Text style={{fontFamily: "sans-serif", fontSize: 12, color: 'red', marginTop: 2}}>
                    Please specify an email.
                </Text>
            ) : null}
        </View>
    )
}

function PasswordInput({password, setPassword, error}) {
    const [secureText, setSecureText] = useState(true)

    return (
        <View style={{ marginTop: 35 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Text style={{ fontFamily: "Inter", fontWeight: '500', color: '#282a35', fontSize: 16 }}>
                    Password
                </Text>
                <Pressable onPress={() => setSecureText(!secureText)} style={{ alignItems: 'center', flexDirection: 'row', gap: 8 }}>
                    <Entypo name={secureText ? "eye" : "eye-with-line"} size={15} color={"#282a35"} />
                    <Text style={{ fontFamily: "PoppinsSemiBold", color: '#282a35', fontSize: 12, userSelect: 'none' }}>{secureText ? "Show" : "Hide"}</Text>
                </Pressable>
            </View>
            <Input style={error ? {borderColor: 'red'} : null} value={password} onChangeText={(t) => setPassword(t)} secureTextEntry={secureText} />
            {error ? (
                <Text style={{fontFamily: "sans-serif", fontSize: 12, color: 'red', marginTop: 2}}>
                    Please specify a password.
                </Text>
            ) : null}
        </View>
    )
}

function LoginButton({...props}) {
    let style = { backgroundColor: 'rgb(26, 53, 105)', alignItems: 'center', marginTop: 35, paddingVertical: 13, borderRadius: 100 }
    const [hovered, setHovered] = useState(false)

    return (
        <Pressable {...props} onHoverIn={() => setHovered(true)} onHoverOut={() => setHovered(false)} style={[style, {backgroundColor: hovered ? '#192655' : '#192655'}]}>
            <Text style={{ color: 'white', fontFamily: "PoppinsSemiBold", fontSize: 16, userSelect: 'none' }}>
                Log in
            </Text>
        </Pressable>
    )
}

function LoginBox({ navigation }) {
    const authContext = useContext(AuthContext)
    
    const {width, height} = useWindowDimensions()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [error, setError] = useState(false)

    const [emailError, setEmailError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)

    const validate = () => {
        let success = true
        if (email.trim() == '') {
            setEmailError(true)
            success = false
        }
        if (password.trim() == '') {
            setPasswordError(true)
            success = false
        }
        return success
    }

    const clearErrors = () => {
        setError(false)
        setEmailError(false)
        setPasswordError(false)
    }

    const getAuthenticated = async (token) => {
        let authenticated = await fetch(`${API_URL}/api/authenticated`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })

        if (authenticated.ok) {
            return await authenticated.json()
        }
    }

    const handleLogin = async () => {
        if (validate()) {
            clearErrors()
            let accountRequest = await fetch(`${API_URL}/api/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "email": email.trim(),
                    "password": password.trim()
                })
            })

            const response = await accountRequest.json()

            if (!accountRequest.ok) {
                setError(response["error"]["msg"])
            } else {
                await AsyncStorage.setItem("token", response["token"])
                const authenticated = await getAuthenticated(response["token"])
                authContext.setUser(authenticated)
                authContext.setToken(response["token"])
                navigation.push("Home")
            }
        }
    }

    return (
        <View style={{ padding: 48, borderRadius: 15, borderWidth: width > 500 ? 1 : 0, borderColor: '#e3e3e3', width: width > 600 ? 500 : '100%' }}>
            {error ? (
                <View style={{backgroundColor: '#fecaca', borderRadius: 4, padding: 15, marginBottom: 5}}>
                    <Text style={{color: '#282a35'}}>
                        {error}
                    </Text>
                </View>
            ) : null}
            <Text style={{ fontSize: 24, fontFamily: "Inter", fontWeight: '500', color: 'rgb(15, 15, 15)' }}>
                Log in
            </Text>

            <EmailInput email={email} setEmail={setEmail} error={emailError} />
            <PasswordInput password={password} setPassword={setPassword} error={passwordError} />

            <LoginButton onPress={handleLogin} />
        </View>
    )
}

export function Login({ navigation }) {
    const ready = useLang()
    if (!ready) return null

    return (
        <View style={styles.container}>
            <Header />
            <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                <LoginBox navigation={navigation} />
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white'
    },
    input: {
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#e3e3e3',
        height: 50,
        marginTop: 10,
        fontSize: 14,
        paddingHorizontal: 10,
        fontFamily: "PoppinsRegular",
        spellCheck: false
    }
})