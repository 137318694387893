import { useWindowDimensions, StyleSheet, View } from "react-native";

export const ResponsiveView = ({children, style, customWidth, ...props}) => {
    const {width, height} = useWindowDimensions()

    let maxWidth = customWidth;
    if (!customWidth) maxWidth = 1000
    
    return (
        <View {...props} style={[style, width > maxWidth ? {width: maxWidth} : styles.bodyWithMobile]}>
            {children}
        </View>
    )
}

const styles = StyleSheet.create({
    bodyWidthLarge: {
        width: 1000,
    },
    bodyWithMobile: {
        width: '100%',
        paddingHorizontal: 15
    },
})