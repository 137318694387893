import { API_URL } from "@env"
import { useNavigation } from "@react-navigation/native"
import { useContext, useEffect, useState } from "react"
import { Helmet } from 'react-helmet'
import { useTranslation } from "react-i18next"
import { Image, Pressable, StyleSheet, View, useWindowDimensions } from "react-native"
import { Footer } from "./Footer"
import { Header } from "./Header"
import { languageMap, lawFieldMap, serviceMap } from "./Mapping"
import { ProfileContext } from "./ProfileContext"
import { ResponsiveView } from "./ResponsiveView"
import { MixedFontText } from "./components/MixedFontText"
import { MobileDownloadModal } from "./components/MobileDownloadModal"
import { ProfilePicture } from "./components/ProfilePicture"
import { Text } from "./components/Text"
import { AuthContext } from "./contexts/AuthContext"
import useLang from "./hooks/use-lang"

function NotFound() {
    return (
        <View style={{flex: 1, alignItems: 'center'}}>
            <View style={{marginTop: 50, backgroundColor: 'white', padding: 20}}>
                <Text style={{fontFamily: "sans-serif", fontSize: 25}}>Consultant could not be found</Text>
                <Text style={{marginTop: 20}}>404 | Page Not Found</Text>
            </View>
        </View>
    )
}

function PrivateProfile() {
    return (
        <View style={{flex: 1, alignItems: 'center'}}>
            <View style={{marginTop: 50, backgroundColor: 'white', padding: 20}}>
                <Text style={{fontFamily: "sans-serif", fontSize: 25}}>Private Profile</Text>
                <Text style={{marginTop: 20}}>This profile is private and cannot be viewed.</Text>
            </View>
        </View>
    )
}

function Error() {
    return (
        <View style={{flex: 1, alignItems: 'center'}}>
            <View style={{marginTop: 50, backgroundColor: 'white', padding: 20}}>
                <Text style={{fontFamily: "sans-serif", fontSize: 25}}>Something went wrong</Text>
                <Text style={{marginTop: 20}}>500 | Internal server error</Text>
            </View>
        </View>
    )
}

function ContactSection({goToForm}) {
    const authContext = useContext(AuthContext)
    const {height, width} = useWindowDimensions()
    const [modalVisible, setModalVisible] = useState(false)
    const {t} = useTranslation()
    const navigation = useNavigation()

    return (
        <View>
            <MobileDownloadModal visible={modalVisible} setVisible={setModalVisible} />
            <View style={{gap: 10}}>
                <Pressable style={{marginTop: 15}} onPress={() => {
                    if (authContext.token) {
                        goToForm()
                    } else {
                        setModalVisible(true)
                    }
                }}>
                    <View style={[styles.contactButton, width < 725 ? {paddingVertical: 9} : null]}>
                        <Text style={styles.contactText}>BOOK APPOINTMENT</Text>
                    </View>
                </Pressable>
                {authContext.token ? (
                    <Pressable onPress={() => setModalVisible(true)}>
                        <View style={[styles.contactButton, {backgroundColor: '#f3f4f6'}, width < 725 ? {paddingVertical: 9} : null]}>
                            <Text style={[styles.contactText, {color: 'black'}]}>CHAT</Text>
                        </View>
                    </Pressable>
                ) : null}
            </View>
        </View>
    )
}

function MainProfileWidget({userInfo}) {
    const authContext = useContext(AuthContext)
    const navigation = useNavigation()

    const goToForm = () => {
        navigation.push("Form", {
            consultantId: userInfo["id"],
            name: userInfo["name"],
            userId: userInfo["user"]
        })
    }

    return (
        <View style={styles.widget}>
            <View style={{alignItems: 'center'}}>
                <ProfilePicture
                    isProfilePicture={userInfo["profile_picture"]}
                    userId={userInfo["user"]}
                    name={userInfo["name"]}
                    style={{borderRadius: 20, width: 120, height: 120}}
                />
                <Text style={{fontFamily: "PoppinsSemiBold, NotoSansSemiBold", marginTop: 8}}>
                    {userInfo["name"]}
                </Text>
                <Text style={{fontSize: 12, color: 'gray', fontFamily: "sans-serif"}}>
                    {userInfo["occupation"]}
                </Text>
            </View>
            <ContactSection goToForm={goToForm} />
        </View>
    )
}

function Item({item}) {
    return (
        <View style={{borderWidth: 1, padding: 4, paddingHorizontal: 8, borderRadius: 100, borderColor: '#e6e6e6', alignSelf: 'flex-start'}}>
            <Text style={{fontFamily: "sans-serif", fontSize: 12}}>{item}</Text>
        </View>
    )
}

function QualificationsWidget({userInfo}) {
    const {t, i18n} = useTranslation()

    return (
        <View style={[styles.widget, {gap: 15}]}>
            {userInfo["spoken_languages"].length > 0 ? (
                <View style={{alignItems: i18n.language == "ar" ? 'flex-end' : 'flex-start'}}>
                    <Text style={styles.subHeader}>{t("consultantProfile.languages")}</Text>
                    <View style={{flexDirection: i18n.language == "ar" ? 'row-reverse' : 'row', flexWrap: 'wrap', gap: 5}}>
                        {userInfo["spoken_languages"].map(lang =>
                            <Item key={lang} item={languageMap[lang]} />)}
                    </View>
                </View>
            ) : null}
            <View style={{alignItems: i18n.language == "ar" ? 'flex-end' : 'flex-start'}}>
                <Text style={[styles.subHeader]}>{t("consultantProfile.aoe")}</Text>
                <View style={{flexDirection: i18n.language == "ar" ? 'row-reverse' : 'row', flexWrap: 'wrap', gap: 5}}>
                    {userInfo["specialized_fields"].map(field =>
                        <Item key={field} item={lawFieldMap[field]} />)}
                </View>
            </View>
            {userInfo["services"].length > 0 ? (
                <View style={{alignItems: i18n.language == "ar" ? 'flex-end' : 'flex-start'}}>                
                    <Text style={[styles.subHeader]}>{t("consultantProfile.services")}</Text>
                    <View style={{flexDirection: i18n.language == "ar" ? 'row-reverse' : 'row', flexWrap: 'wrap', gap: 5}}>
                        {userInfo["services"].map(service =>
                            <Item key={service} item={serviceMap[service]} />)}
                    </View>
                </View>
            ) : null}
        </View>
    )
}

function StatisticsItem({icon, text}) {
    return (
        <View style={{alignItems: 'center', maxWidth: 50}}>
            <View style={{padding: 10, backgroundColor: '#f2f2f2', borderRadius: 100}}>
                {icon}
            </View>
            <Text style={{fontSize: 11, fontFamily: "sans-serif", textAlign: 'center', marginTop: 5}}>{text}</Text>
        </View>
    )
}

function StatisticsWidget({userInfo}) {
    return (
        <View style={styles.widget}>
            <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                <StatisticsItem
                    icon={<Image source={require("./assets/suitcase.webp")} style={{width: 30, height: 30}} />}
                    text={userInfo["statistics"]["experience"] > 1 ? `${userInfo["statistics"]["experience"]} Years Experience` : `1 Year Experience`}
                />
                <StatisticsItem
                    icon={<Image source={require("./assets/clients.webp")} style={{width: 30, height: 30, tintColor: 'black'}} />}
                    text={userInfo["statistics"]["client_count"] > 1 ? `${userInfo["statistics"]["client_count"]} Clients Served` :
                        `1 Client Served`}
                />
                <StatisticsItem
                    icon={<Image source={require("./assets/star.webp")} style={{width: 30, height: 30, tintColor: 'black'}} />}
                    text={userInfo["statistics"]["rating"] == 0 ? "Not Yet Rated" : userInfo["statistics"]["rating"]}
                />
            </View>
        </View>
    )
}

function DescriptionSection({userInfo}) {
    const {t} = useTranslation()

    return (
        <View style={styles.widget}>
            <View style={{borderBottomWidth: 1, paddingBottom: 5, borderBottomColor: '#f2f2f2'}}>
                <Text style={{fontWeight: "bold", fontSize: 16, fontFamily: "sans-serif"}}>
                    {t("consultantProfile.description")}
                </Text>
            </View>
            <MixedFontText arabicStyle={{fontFamily: "NotoSansRegular"}} style={{marginTop: 5, color: 'gray', marginTop: 10, fontFamily: "PoppinsRegular"}}>{userInfo["description"]}</MixedFontText>
        </View>
    )
}

function ProfileSection({userInfo}) {
    const {height, width} = useWindowDimensions()

    return (
        <View style={{flex: 1, alignItems: 'center'}}>
            <ResponsiveView customWidth={900}>
                <View style={{flex: 1, flexDirection: width > 725 ? 'row' : 'column', marginVertical: 70, gap: 15}}>
                    {width > 725 ? (
                        <>                        
                            <View style={{flex: 18}}>
                                <DescriptionSection userInfo={userInfo} />
                            </View>
                            <View style={{flex: 8, gap: 15}}>
                                <MainProfileWidget userInfo={userInfo} />
                                <StatisticsWidget userInfo={userInfo} />
                                <QualificationsWidget userInfo={userInfo} />
                            </View>
                        </>
                    ) : (
                        <>                        
                            <View style={{gap: 15}}>
                                <MainProfileWidget userInfo={userInfo} />
                                <StatisticsWidget userInfo={userInfo} />
                                <QualificationsWidget userInfo={userInfo} />
                            </View>
                            <View style={{marginBottom: 200}}>
                                <DescriptionSection userInfo={userInfo} />
                            </View>
                        </>
                    )}
                </View>
            </ResponsiveView>
        </View>
    )
}

function Metadata({userInfo}) {
    return (
        <Helmet>
            <meta property="og:title" content={userInfo?.name} />
            <meta property="og:description" content={userInfo?.description} />
            <meta name="description" content={userInfo?.description} />
        </Helmet>
    )
}

export function Profile({navigation, route}) {
    const profileContext = useContext(ProfileContext)
    const {userId} = route.params
    const [userInfo, setUserInfo] = useState()
    const [notFound, setNotFound] = useState(false)
    const [privateProfile, setPrivateProfile] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        if (profileContext.profile?.id == userId) {
            navigation.setOptions({
                title: profileContext.profile.name
            })
            setUserInfo(profileContext.profile)
            setNotFound(false)
            setPrivateProfile(false)
        } else {
            fetch(`${API_URL}/api/consultant/${userId}`).then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        navigation.setOptions({
                            title: data["name"]
                        })
                        setUserInfo(data)
                        setNotFound(false)
                        setPrivateProfile(false)
                    })
                } else {
                    if (response.status == 404) {
                        setNotFound(true)
                    } else if (response.status == 401) {
                        setPrivateProfile(true)
                    }
                }
            }).catch(error => {
                setError(true)
            })
        }
    }, [userId])

    if (privateProfile) {
        return (
            <View style={styles.container}>
                <Header />
                <PrivateProfile />
            </View>
        )
    }
    
    return (
        <View style={styles.container}>
            <Metadata userInfo={userInfo} />
            <Header />
            {notFound ? (
                <NotFound />
            ) : (
                <>
                {!userInfo ? (
                    <View />
                ) : (
                    <>     
                        {error ? (
                            <Error />
                        ) : (
                            <ProfileSection userInfo={userInfo} />
                        )}
                    </>
                )}
                </>
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    widget: {
        borderColor: '#e6e6e6',
        borderRadius: 15,
        borderWidth: 0,
        padding: 15,
        backgroundColor: 'white',
    },
    contactText: {
        color: 'white',
        fontWeight: 'bold',
        fontFamily: "sans-serif",
        fontSize: 12.5,
        letterSpacing: 0.3
    },
    contactButton: {
        backgroundColor: '#192655',
        alignItems: 'center',
        borderRadius: 100,
        paddingVertical: 7,
        userSelect: 'none'
    },
    subHeader: {
        fontWeight: 'bold',
        marginBottom: 10,
    }
})