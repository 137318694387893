import React, {useState} from "react"

const AuthContext = React.createContext()

const AuthProvider = ({children}) => {
    const [user, setUser] = useState({})
    const [token, setToken] = useState(null)
    const [loading, setLoading] = useState(true)

    const authContext = {
        name: user?.user_info != undefined ? (user.user_info.first_name + ' ' + user.user_info.last_name) : null,
        user: user,
        setUser: setUser,
        token: token,
        setToken: setToken,
        loading: loading,
        setLoading: setLoading
    }

    return (
        <AuthContext.Provider value={authContext}>
            {children}
        </AuthContext.Provider>
    )
}

export {AuthContext, AuthProvider}