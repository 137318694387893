import { API_URL } from "@env";
import { Link } from '@react-navigation/native';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Image, Linking, Picker, Pressable, StyleSheet, TextInput, View, useWindowDimensions } from 'react-native';
import AntDesign from 'react-native-vector-icons/AntDesign';
import Entypo from 'react-native-vector-icons/Entypo';
import { Footer } from './Footer';
import { Header } from './Header';
import { ResponsiveView } from './ResponsiveView';
import { MobileDownloadModal } from './components/MobileDownloadModal';
import { ProfilePicture } from './components/ProfilePicture';
import { Text } from './components/Text';
import { AuthContext } from './contexts/AuthContext';
import useLang from './hooks/use-lang';

function FrontItems({ style, setModalVisible }) {
  const { height, width } = useWindowDimensions()
  const { t, i18n } = useTranslation()

  const getTitleFontSize = () => {
    if (width >= 1200) {
      return 47
    } else if (width >= 992) {
      return 40
    } else if (width >= 768) {
      return 30
    } else if (width >= 600) {
      return 27
    } else {
      return 25
    }
  }

  const getTextFontSize = () => {
    if (width >= 1200) {
      return 22
    } else if (width >= 992) {
      return 18
    } else {
      return 14
    }
  }

  return (
    <View style={[{ flex: 1 }, style]}>
      <Text accessibilityRole="heading" accessibilityLevel={"1"} style={{ fontFamily: "PoppinsSemiBold, NotoSansBold", fontSize: getTitleFontSize(), fontWeight: '500', textAlign: 'center', letterSpacing: 1.5, userSelect: 'none', color: 'rgb(15, 23, 42)' }}>
        {t("landingPage.intro.slogan")}
      </Text>
      <Text accessibilityRole="heading" accessibilityLevel="2" style={{ color: 'rgb(35, 35, 64)', fontWeight: '100', fontSize: getTextFontSize(), textAlign: 'center', marginTop: 15, fontFamily: "sans-serif", fontFamily: "PoppinsRegular, NotoSansRegular", userSelect: 'none' }}>
        {t("landingPage.intro.sloganDescription")}
      </Text>
      <View style={{ flexDirection: width >= 600 ? (i18n.language == "ar" ? "row-reverse" : "row") : 'column', gap: 10, marginTop: 25, alignItems: 'center' }}>
        <Pressable style={[styles.highlightHeaderButton]} onPress={() => setModalVisible(true)}>
          <Text style={[{ color: 'white', fontFamily: "PoppinsSemiBold, NotoSansBold", userSelect: 'none' }, width < 600 ? { fontSize: 12 } : null]}>{t("landingPage.intro.getAppButton")}</Text>
        </Pressable>
        <Link style={[styles.secondaryButton, { color: 'black', fontFamily: "PoppinsMedium, NotoSansMedium", userSelect: 'none' }, width < 600 ? { fontSize: 12 } : null]} to={`${i18n.language == "ar" ? "/ar" : ""}/lawyers`}>
          {t("landingPage.intro.viewConsultants")}
        </Link>
      </View>
    </View>
  )
}

function Front({ navigation }) {
  const { height, width } = useWindowDimensions()
  const { t, i18n } = useTranslation()
  const [modalVisible, setModalVisible] = useState(false)

  const getImageSize = () => {
    if (width >= 900) {
      return { height: 430, width: 515.89 }
    } else if (width > 600) {
      return { height: 300, width: 359.92 }
    } else if (width > 400) {
      return { height: 250, width: 299.93 }
    } else {
      return { height: 200, width: 239.95 }
    }
  }

  return (
    <View style={{ alignItems: 'center', paddingBottom: 35 }}>
      <ResponsiveView customWidth={1200} style={{ flexDirection: 'column', alignItems: 'center', paddingVertical: 25 }}>
        <MobileDownloadModal suppressLogin visible={modalVisible} setVisible={setModalVisible} />
        <FrontItems style={width < 900 ? { alignItems: 'center' } : { alignItems: 'center', marginTop: 50, marginVertical: 25 }} setModalVisible={setModalVisible} />
      </ResponsiveView>
    </View>
  )
}

function FeatureItem({ icon, title, description }) {
  const { t, i18n } = useTranslation()

  return (
    <View style={[styles.featureItem, { alignItems: i18n.language == "ar" ? 'flex-end' : 'flex-start' }]}>
      <View style={styles.featureItemIcon}>
        {icon}
      </View>
      <Text style={{ fontWeight: 'bold', marginVertical: 5 }}>{title}</Text>
      <Text>{description}</Text>
    </View>
  )
}

function Features() {
  const { height, width } = useWindowDimensions()
  const { t } = useTranslation()


  return (
    <View style={{ backgroundColor: 'rgb(245, 245, 247)', alignItems: 'center', paddingVertical: 45 }}>
      <View style={{ flexDirection: width < 1000 ? 'column' : 'row', flexWrap: 'wrap', gap: 25, alignItems: 'center' }}>
        <FeatureItem
          icon={<Entypo name="stopwatch" size={25} />}
          title={t("landingPage.overview.instant")}
          description={t("landingPage.overview.instantDesc")}
        />
        <FeatureItem
          icon={<Entypo name="link" size={25} />}
          title={t("landingPage.overview.publicInquiry")}
          description={t("landingPage.overview.publicInquiryDesc")}
        />
        <FeatureItem
          icon={<AntDesign name="calendar" size={25} />}
          title={t("landingPage.overview.appointments")}
          description={t("landingPage.overview.appointmentsDesc")}
        />
      </View>
    </View>
  )
}

function FeaturesSwiperSelect({ text, selected, onSelect }) {
  const { height, width } = useWindowDimensions()

  let textStyling = { fontSize: width < 700 ? 12 : 20, color: selected ? 'rgb(128, 155, 255)' : 'rgba(229, 231, 235, 0.5)' }

  return (
    <Pressable style={{ flex: 1, marginHorizontal: width < 700 ? 12 : 20 }} onPress={onSelect}>
      <View style={[{ flex: 1 }, selected ? { borderBottomWidth: 3, borderBottomColor: 'rgb(128, 155, 255)' } : null]}>
        <Text style={[styles.selectStyle, textStyling]}>
          {text}
        </Text>
      </View>
    </Pressable>
  )
}

function FeaturesSwiperHeaderText({ children, ...props }) {
  return (
    <Text style={{ color: 'white', fontFamily: "PoppinsSemiBold, NotoSansSemiBold", fontSize: 37, textAlign: 'center' }} {...props}>
      {children}
    </Text>
  )
}

function FeaturesSwiperSubText({ children, style, ...props }) {
  return (
    <Text style={[{ color: 'white', fontFamily: "PoppinsMedium, NotoSansMedium", fontSize: 20, textAlign: 'center' }, style]} {...props}>
      {children}
    </Text>
  )
}

function BulletPoint({ title, description, mode }) {
  const { i18n } = useTranslation()

  return (
    <View style={{ flexDirection: i18n.language == "ar" ? 'row-reverse' : 'row', flex: 1 }}>
      <View style={{ paddingHorizontal: 8 }}>
        <View style={{ width: 5, height: 5, borderRadius: 30, backgroundColor: 'white', marginTop: 10 }} />
      </View>
      <View style={{ flex: 1 }}>
        <Text style={{ fontFamily: "PoppinsMedium, NotoSansMedium", color: 'white', fontSize: 16, fontWeight: 'bold' }}>{title}</Text>
        <Text style={{ fontFamily: "PoppinsMedium, NotoSansMedium", color: mode == true ? 'white' : 'rgb(205, 217, 251)', fontSize: 14 }}>{description}</Text>
      </View>
    </View>
  )
}

function FeaturesCircle({ style }) {
  return (
    <View style={[{ borderRadius: 100, width: 100, height: 100, position: 'absolute', right: 300, zIndex: -1 }, style]} />
  )
}

function FeaturesSwiperPageOne() {
  const { t } = useTranslation()

  return (
    <View style={[styles.swiperPage, { backgroundColor: 'rgb(3, 63, 236)' }]}>
      <FeaturesCircle style={{ backgroundColor: 'rgba(23, 78, 235, 0.7)', right: 300 }} />
      <FeaturesCircle style={{ backgroundColor: 'rgba(23, 78, 235, 0.7)', width: 80, height: 80, right: 200, bottom: 50 }} />
      <FeaturesCircle style={{ backgroundColor: 'rgba(23, 78, 235, 0.7)', width: 40, height: 40, left: 100, top: 200 }} />
      <FeaturesCircle style={{ backgroundColor: 'rgba(23, 78, 235, 0.7)', width: 40, height: 40, left: 80, bottom: 100 }} />
      <FeaturesSwiperHeaderText>
        {t("landingPage.slider.page1.title")}
      </FeaturesSwiperHeaderText>
      <FeaturesSwiperSubText style={{ marginTop: 10 }}>
        {t("landingPage.slider.page1.description")}
      </FeaturesSwiperSubText>

      <View style={{ marginVertical: 40, gap: 25, flex: 1 }}>
        <BulletPoint title={t("landingPage.slider.page1.feature1title")} description={t("landingPage.slider.page1.feature1desc")} />
        <BulletPoint title={t("landingPage.slider.page1.feature2title")} description={t("landingPage.slider.page1.feature2desc")} />
        <BulletPoint title={t("landingPage.slider.page1.feature3title")} description={t("landingPage.slider.page1.feature3desc")} />
      </View>
    </View>
  )
}

function FeaturesSwiperPageTwo() {
  const { t } = useTranslation()

  return (
    <View style={[styles.swiperPage, { backgroundColor: 'rgb(255, 168, 0)' }]}>
      <FeaturesCircle style={{ backgroundColor: 'rgba(255, 208, 0, 0.4)', right: 150, bottom: 50 }} />
      <FeaturesCircle style={{ backgroundColor: 'rgba(255, 208, 0, 0.4)', width: 80, height: 80, top: 100, right: 300 }} />
      <FeaturesCircle style={{ backgroundColor: 'rgba(255, 208, 0, 0.4)', width: 60, height: 60, left: 200, top: 250 }} />
      <FeaturesSwiperHeaderText>
        {t("landingPage.slider.page2.title")}
      </FeaturesSwiperHeaderText>
      <FeaturesSwiperSubText style={{ marginTop: 10 }}>
        {t("landingPage.slider.page2.description")}
      </FeaturesSwiperSubText>

      <View style={{ marginVertical: 40, gap: 25 }}>
        <BulletPoint mode title={t("landingPage.slider.page2.feature1title")} description={t("landingPage.slider.page2.feature1desc")} />
        <BulletPoint mode title={t("landingPage.slider.page2.feature2title")} description={t("landingPage.slider.page2.feature2desc")} />
        <BulletPoint mode title={t("landingPage.slider.page2.feature3title")} description={t("landingPage.slider.page2.feature3desc")} />
      </View>
    </View>
  )
}

function FeaturesSwiperPageThree() {
  const { t } = useTranslation()

  return (
    <View style={[styles.swiperPage, { backgroundColor: 'rgb(55, 198, 143)' }]}>
      <FeaturesCircle style={{ backgroundColor: 'rgba(64, 227, 164, 0.5)', right: 300, width: 70, height: 70 }} />
      <FeaturesCircle style={{ backgroundColor: 'rgba(64, 227, 164, 0.5)', width: 80, height: 80, right: 100, bottom: 25 }} />
      <FeaturesCircle style={{ backgroundColor: 'rgba(64, 227, 164, 0.5)', width: 40, height: 40, left: 230, top: 100 }} />
      <FeaturesCircle style={{ backgroundColor: 'rgba(64, 227, 164, 0.5)', width: 40, height: 40, left: 200, bottom: 100 }} />
      <FeaturesSwiperHeaderText>
        {t("landingPage.slider.page3.title")}
      </FeaturesSwiperHeaderText>
      <FeaturesSwiperSubText style={{ marginTop: 10 }}>
        {t("landingPage.slider.page3.description")}
      </FeaturesSwiperSubText>

      <View style={{ marginVertical: 40, gap: 25 }}>
        <BulletPoint mode title={t("landingPage.slider.page3.feature1title")} description={t("landingPage.slider.page3.feature1desc")} />
        <BulletPoint mode title={t("landingPage.slider.page3.feature2title")} description={t("landingPage.slider.page3.feature2desc")} />
        <BulletPoint mode title={t("landingPage.slider.page3.feature3title")} description={t("landingPage.slider.page3.feature3desc")} />
      </View>
    </View>
  )
}

function PageSwitchButton({ side, onPress }) {
  return (
    <View style={{ justifyContent: 'center', margin: 20 }}>
      <Pressable onPress={onPress}>
        <View style={styles.pageSwitchButton}>
          {side == 'left' ? <AntDesign name="arrowleft" size={35} color={"white"} /> : null}
          {side == 'right' ? <AntDesign name="arrowright" size={35} color={"white"} /> : null}
        </View>
      </Pressable>
    </View>
  )
}

function FeaturesSwiper() {
  const [currentPage, setCurrentPage] = useState(1) // 1 - 3
  const { t } = useTranslation()
  const { height, width } = useWindowDimensions()

  const goLeft = () => {
    if (currentPage == 1) {
      setCurrentPage(3)
    } else {
      setCurrentPage(currentPage - 1)
    }
  }

  const goRight = () => {
    if (currentPage == 3) {
      setCurrentPage(1)
    } else {
      setCurrentPage(currentPage + 1)
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flexDirection: 'row', borderBottomWidth: 2, borderBottomColor: 'rgb(67, 67, 115)' }}>
        <FeaturesSwiperSelect text={t("landingPage.slider.easeOfUse")} selected={currentPage == 1} onSelect={() => setCurrentPage(1)} />
        <FeaturesSwiperSelect text={t("landingPage.slider.publicInquiry")} selected={currentPage == 2} onSelect={() => setCurrentPage(2)} />
        <FeaturesSwiperSelect text={t("landingPage.slider.appointments")} selected={currentPage == 3} onSelect={() => setCurrentPage(3)} />
      </View>
      <View style={{ marginTop: 38, flexDirection: 'row' }}>
        {width > 767 ? <PageSwitchButton side={"left"} onPress={goLeft} /> : null}
        {currentPage == 1 ? (
          <FeaturesSwiperPageOne />
        ) : null}
        {currentPage == 2 ? (
          <FeaturesSwiperPageTwo />
        ) : null}
        {currentPage == 3 ? (
          <FeaturesSwiperPageThree />
        ) : null}
        {width > 767 ? <PageSwitchButton side={"right"} onPress={goRight} /> : null}
      </View>
    </View>
  )
}

function FeaturesOverview() {
  return (
    <View style={{ alignItems: 'center', backgroundColor: 'rgb(37, 37, 63)' }}>
      <ResponsiveView style={{ marginVertical: 55 }} customWidth={1100}>
        <FeaturesSwiper />
      </ResponsiveView>
    </View>
  )
}

function Introduction({ navigation }) {
  const { height, width } = useWindowDimensions()
  const { t, i18n } = useTranslation()

  let alignment = i18n.language == "ar" ? "flex-end" : "flex-start"
  let textAlignment = i18n.language == "ar" ? "right" : "left"

  return (
    <View style={{ backgroundColor: 'white', alignItems: 'center', paddingVertical: 45 }}>
      <ResponsiveView style={[{ marginTop: 30, flexDirection: 'row' }]}>
        {width > 768 ? <Image source={require("./assets/phone.webp")} style={{ width: 300, height: 500 }} /> : null}
        <View style={{ flex: 1 }}>
          <Text accessibilityRole="heading" accessibilityLevel={2} style={[styles.introTitle, { textAlign: width > 768 ? textAlignment : 'center' }]} role="heading">
            {t("landingPage.detail.title")}
          </Text>
          <Text style={[styles.introDescription, { textAlign: width > 768 ? textAlignment : 'center' }]}>
            {t("landingPage.detail.description")}
          </Text>
          <Link style={[styles.secondaryButton, { marginTop: 15, alignSelf: width > 768 ? alignment : 'center' }, { color: 'black', fontFamily: "PoppinsMedium", userSelect: 'none' }]} to={"/lawyers"}>
            {t("landingPage.intro.viewConsultants")}
          </Link>
        </View>
      </ResponsiveView>
    </View>
  )
}

function DownloadAd() {
  const [visible, setVisible] = useState(false)
  const {width} = useWindowDimensions()

  return (
    <View style={[{ padding: 20, borderRadius: 5, flexDirection: width > 600 ? 'row' : 'column', alignItems: 'center', gap: 25 }, shadowStyles.shadowStyle3]}>
      <MobileDownloadModal visible={visible} setVisible={setVisible} />
      <View style={{flex: 1}}>
        <Text style={{fontFamily: "PoppinsMedium", fontSize: 17}}>
          Download the mobile app
        </Text>
        <Text style={{fontFamily: "PoppinsRegular", marginTop: 5}}>
          Download the Consult Me mobile application and communicate live with the consultant of your choice.
        </Text>
      </View>
      <Pressable onPress={() => setVisible(true)} style={{padding: 10, backgroundColor: '#192655', alignItems: 'center', justifyContent: 'center', borderRadius: 100, paddingHorizontal: 25, height: 40}}>
        <Text style={{color: 'white', userSelect: 'none'}}>
          Download app
        </Text>
      </Pressable>
    </View>
  )
}

function ConsultantPreview({ navigation, ...props }) {
  const { t, i18n } = useTranslation()
  const [hovered, setHovered] = useState(false)

  const getName = () => {
    if (i18n.language == 'ar' && props.consultant.name_ar) {
      return props.consultant.name_ar
    } else {
      return props.consultant.name
    }
  }

  const navigateToConsultant = () => {
    navigation.push('Profile', {
      userId: props.consultant.id,
    })
  }

  return (
    <Pressable style={[styles.consultantPreview, hovered ? shadowStyles.shadowStyle3Hovered : shadowStyles.shadowStyle3]} onPress={() => navigateToConsultant()}
      onHoverIn={() => setHovered(true)} onHoverOut={() => setHovered(false)}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <ProfilePicture
          isProfilePicture={props.consultant.profile_picture}
          userId={props.consultant.user}
          name={props.consultant.name}
          style={{ width: 70, height: 70, borderRadius: 10 }}
          textStyle={{ fontSize: 30 }}
        />
        <View style={{ marginLeft: 10 }}>
          <Text style={{ color: '#414141', fontFamily: 'PoppinsMedium' }}>{getName()}</Text>
          <Text style={{ color: '#acadbd', fontFamily: 'PoppinsLight', writingDirection: i18n.language == 'ar' ? 'rtl' : 'ltr' }}>{props.consultant.occupation}</Text>
        </View>
      </View>
      {i18n.language == 'ar' ? (
        <AntDesign name="left" size={25} color={"#d6d6d6"} />
      ) : (
        <AntDesign name="right" size={25} color={"#d6d6d6"} />
      )}
    </Pressable>
  )
}

function Selectable({ categoryText, value, selected, setSelected }) {
  let base = { borderColor: '#192655', borderWidth: 1, borderRadius: 100, paddingHorizontal: 12, paddingVertical: 5 }
  const {width} = useWindowDimensions()

  return (
    <Pressable style={[base, selected == value ? { backgroundColor: '#192655' } : null]} onPress={() => setSelected(value)}>
      <Text style={{ color: selected == value ? 'white' : '#192655', userSelect: 'none', fontSize: width > 600 ? 14 : 12 }}>{categoryText}</Text>
    </Pressable>
  )
}

function LawyersList({ navigation }) {
  const [consultants, setConsultants] = useState([])
  const [loading, setLoading] = useState(true)
  const seed = Math.floor(Math.random() * 10000)
  const [selected, setSelected] = useState("all")
  const {width} = useWindowDimensions()

  const lawFieldMap = {
    "civil law": "Civil Law",
    "criminal law": "Criminal Law",
    "family law": "Family Law",
    "banking law": "Banking Law",
    "commercial law": "Commercial Law",
    "labor law": "Labor Law",
    "private law": "Private Law",
    "public law": "Public Law"
  }

  const getConsultantsAsync = async () => {
    var consultants = await fetch(`${API_URL}/api/consultant/list?` + new URLSearchParams({
      order_by: 'random',
      seed: seed,
      limit: '15',
      category: selected
    }))
    if (consultants.ok) {
      setLoading(true)
      setConsultants(await consultants.json())
      setLoading(false)
    }
  }

  useEffect(() => {
    getConsultantsAsync()
  }, [selected])

  return (
    <View style={width > 600 ? { flex: 3 } : null}>
      <Pressable style={{flexDirection: 'row', gap: 3, alignItems: 'center', alignSelf: 'flex-start'}} onPress={() => navigation.push('Consultants')}>
        <Text style={{ fontFamily: "PoppinsSemiBold", fontSize: 21, marginBottom: 5, userSelect: 'none' }}>
          Lawyers
        </Text>
        <Entypo name="chevron-right" size={20} style={{marginBottom: 4}} />
      </Pressable>
      <View style={{ alignItems: 'flex-start', flexDirection: 'row', gap: 10, flexWrap: 'wrap', marginTop: 20, marginBottom: 30 }}>
        <Selectable value="all" categoryText={"All"} selected={selected} setSelected={setSelected} />
        <Selectable value="civil law" categoryText={"Civil Law"} selected={selected} setSelected={setSelected} />
        <Selectable value="criminal law" categoryText={"Criminal Law"} selected={selected} setSelected={setSelected} />
        <Selectable value="family law" categoryText={"Family Law"} selected={selected} setSelected={setSelected} />
        <Selectable value="banking law" categoryText={"Banking Law"} selected={selected} setSelected={setSelected} />
        <Selectable value="commercial law" categoryText={"Commercial Law"} selected={selected} setSelected={setSelected} />
        <Selectable value="labor law" categoryText={"Labor Law"} selected={selected} setSelected={setSelected} />
        <Selectable value="private law" categoryText={"Private Law"} selected={selected} setSelected={setSelected} />
        <Selectable value="public law" categoryText={"Public Law"} selected={selected} setSelected={setSelected} />
      </View>

      {loading ? (
        <View style={{ alignItems: 'center', marginTop: 25 }}>
          <ActivityIndicator size={"large"} />
        </View>
      ) : (
        <>
          {consultants.length > 0 ? (
            <>
              {consultants.map(consultant =>
                <ConsultantPreview navigation={navigation} key={consultant["user"]} consultant={consultant} />
              )}
            </>
          ) : (
            <>
              <View style={{ alignItems: 'center', justifyContent: 'center', }}>
                <Text style={{ color: 'gray' }}>No consultants found.</Text>
              </View>
            </>
          )}
        </>
      )}

    </View>
  )
}

function Form({data}) {
  return (
    <View style={{padding: 15, backgroundColor: '#f9f9f9', borderRadius: 10, flexDirection: 'row', gap: 15}}>
      <View>
        <ProfilePicture
          isProfilePicture={data["consultant"]["profile_picture"]}
          userId={data["consultant"]["id"]}
          name={data["consultant"]["display_name"]}
          style={{borderRadius: 100, width: 25, height: 25}}
        />
      </View>
      <View style={{flex: 1}}>
          <Text style={{fontFamily: "PoppinsSemiBold"}}>
            {data["subject"] == "" ? "[No subject provided]" : data["subject"]}
          </Text>
          <Text style={{fontFamily: "PoppinsRegular", fontSize: 12, marginTop: 5}} numberOfLines={2}>
            {data["description"]}
          </Text>
      </View>
    </View>
  )
}

function Forms() {
  const authContext = useContext(AuthContext)
  const [forms, setForms] = useState([])
  const [loading, setLoading] = useState(false)
  const {width} = useWindowDimensions()

  const getForms = async () => {
    const response = await fetch(`${API_URL}/api/form/list-sent`, {
      headers: {
        "Authorization": `Bearer ${authContext.token}`
      }
    })

    if (response.ok) {
      return await response.json()
    }
  }

  useEffect(() => {
    getForms().then(forms => {
      setForms(forms)
    })
  }, [])

  return (
    <View style={[{ marginBottom: 35 }, width > 600 ? {flex: 1.5} : null]}>
      <Text style={{ fontFamily: "PoppinsSemiBold", fontSize: 21, marginBottom: 25 }}>
        My Forms
      </Text>

      {forms.length == 0 ? (
        <Text style={{ color: 'gray' }}>No forms found</Text>
      ) : (
        <View style={{gap: 10}}>
          {forms.map(form => <Form key={form["id"]} data={form} />)}
        </View>
      )}
    </View>
  )
}

function AuthHomepage({ navigation }) {
  const {width} = useWindowDimensions()

  return (
    <View style={{flex: 1}}>
      <View style={{flex: 1, alignItems: 'center', minHeight: '100%'}}>
        <ResponsiveView customWidth={1300}>
          <View style={{ marginTop: 35 }}>
            <DownloadAd />
          </View>
          <View style={{ marginTop: 45 }}>
            <View style={{ flexDirection: width > 600 ? 'row' : 'column', gap: 50 }}>
              <LawyersList navigation={navigation} />
              <Forms />
            </View>
          </View>
        </ResponsiveView>
      </View>
      <Footer />
    </View>
  )
}

export function Home({ navigation, route }) {
  const authContext = useContext(AuthContext)
  const ready = useLang()

  if (!ready) {
    return null
  }

  return (
    <View style={styles.container}>
      <Helmet>
        <meta name="description" content="Based in the Kingdom of Bahrain, Consult Me is the leading platform for individuals seeking expert legal advice. With our user-friendly design, secure communication, and access to a range of specialized consultants, Consult Me is a one-stop destination for professional legal expertise." />
      </Helmet>
      <Header />
      {authContext.token ? (
        <View style={{ flex: 1 }}>
          <AuthHomepage navigation={navigation} />
        </View>
      ) : (
        <View style={styles.outerBody}>
          <View accessibilityRole="main">
            <Front navigation={navigation} />
            <Features />
            <Introduction navigation={navigation} />
            <FeaturesOverview />
          </View>
          <Footer navigation={navigation} />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white'
  },
  outerBody: {
    flex: 1,
  },
  secondaryButton: {
    //backgroundColor: 'rgb(245, 245, 247)',
    borderWidth: 2,
    paddingVertical: 8,
    borderRadius: 5,
    paddingHorizontal: 20,
    borderColor: '#192655'
  },
  body: {
    paddingHorizontal: 10
  },
  bodyWidthLarge: {
    width: 1000,
  },
  bodyWithMobile: {
    width: '100%',
  },
  highlightHeaderButton: {
    backgroundColor: '#192655',
    padding: 10,
    borderRadius: 5,
    paddingHorizontal: 20
  },
  featureItem: {
    padding: 25,
    backgroundColor: "white",
    alignSelf: "flex-start",
    borderRadius: 10,
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.03,
    shadowRadius: 30,
    width: 310,
    height: 200
  },
  featureItemIcon: {
    backgroundColor: 'rgb(245, 245, 247)',
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderRadius: 5,
    marginBottom: 5
  },
  introTitle: {
    fontFamily: "PoppinsSemiBold, NotoSansSemiBold",
    fontSize: 31,
    letterSpacing: 0.5
  },
  introDescription: {
    fontSize: 17,
    fontFamily: "PoppinsRegular, NotoSansRegular",
    marginTop: 15,
    color: 'gray',
    lineHeight: 32,
  },
  pageSwitchButton: {
    backgroundColor: 'rgb(128, 155, 255)',
    borderRadius: 250,
    padding: 15,
    alignItems: 'center',
    justifyContent: 'center',
    aspectRatio: 1
  },
  swiperPage: {
    paddingHorizontal: 35,
    paddingVertical: 45,
    borderRadius: 40,
    minHeight: 450,
    flex: 1,
    overflow: 'hidden'
  },
  selectStyle: {
    fontFamily: "PoppinsBold, NotoSansBold",
    textAlign: 'center',
    marginVertical: 15,
    userSelect: 'none'
  },
  consultantPreview: {
    backgroundColor: 'white',
    padding: 15,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
    borderRadius: 5
  },
});

const shadowStyles = StyleSheet.create({
  shadowStyle3: {
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.175,
    shadowRadius: 6.05,
    elevation: 4
  },
  shadowStyle3Hovered: {
    shadowColor: "#000000",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.20,
    shadowRadius: 7,
    elevation: 4
  }
})
