import {Text as T} from "react-native"
import { useTranslation } from "react-i18next"

export function Text({style, ...props}) {
    const {i18n} = useTranslation()

    return (
        <T style={[{fontFamily: "PoppinsRegular, NotoSansRegular", textAlign: i18n.language == "ar" ? "right" : "left"}, style]} {...props}>
            {props.children}
        </T>
    )
}