import {View, Text, Image} from "react-native"
import {API_URL} from "@env"

function GenericInitialProfilePicture({name, style, textStyle}) {
    var palettes = ["#facc15", "#0ea5e9", "#22c55e", "#f43f5e"]
    var num = 0

    var initials = ""
    var n = name.split(' ')
    for (var i = 0; i < n.length; ++i) {
        var char = n[i][0].toUpperCase()
        initials = initials + char
        num += char.charCodeAt(0)
    }

    return (
        <View style={[{backgroundColor: palettes[num % 4], justifyContent: 'center', alignItems: 'center'}, style]}>
            <Text style={[{fontSize: 40, color: 'white'}, textStyle]} allowFontScaling={false}>{initials}</Text>
        </View>
    )
}

export function ProfilePicture({ isProfilePicture, userId, name, style, textStyle }) {
    return (
        <>
            {
                isProfilePicture ? <Image source={{ uri: `${API_URL}/api/user/${userId}/${isProfilePicture}/profile-picture` }} style={style} /> :
                    <GenericInitialProfilePicture name={name} style={style} textStyle={textStyle} />
            }
        </>
    )
}