import { useTranslation } from "react-i18next"
import { useState, useEffect } from "react"
import { useNavigation, useRoute } from "@react-navigation/native"


export default function useLang() {
    const route = useRoute()
    const navigation = useNavigation()
    const {i18n} = useTranslation()
    const [isReady, setIsReady] = useState(navigation.canGoBack() ? true : false)

    useEffect(() => {
        console.log(route.path)
        if (route.path?.startsWith("/ar")) {
            i18n.changeLanguage("ar")
        } else {
            i18n.changeLanguage("en")
        }
        setIsReady(true)
    }, [i18n.language, route.path])

    return isReady
}