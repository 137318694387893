export function getField(field) {
    switch (field) {
      case "civil law":
        return "Civil Law"
      case "criminal law":
        return "Criminal Law"
      case "family law":
        return "Family Law"
      case "banking law":
        return "Banking Law"
      case "commercial law":
        return "Commercial Law"
      case "labor law":
        return "Labor Law"
      case "private law":
        return "Private Law"
      case "public law":
        return "Public Law"
    }
    return ""
  }
  
  export function getFieldsAsString(fields) {
    var str = ""
    for (var i = 0; i < fields.length; ++i) {
      if (i + 1 != fields.length) {
        str += getField(fields[i]) + ", "
      } else {
        str += getField(fields[i])
      }
    }
    return str
  }
  
  export function getLang(lang) {
    switch (lang) {
      case "english":
        return "English"
      case "arabic":
        return "Arabic"
    }
  }
  
  export function getLanguagesAsString(languages) {
    let str = ""
    for (var i = 0; i < languages.length; ++i) {
      if (i + 1 != languages.length) {
        str += getLang(languages[i]) + ", "
      } else {
        str += getLang(languages[i])
      }
    }
    return str
  }