import { Modal, View, Text, StyleSheet, Pressable, Image, TouchableOpacity, Linking } from "react-native"
import AntDesign from 'react-native-vector-icons/AntDesign'
import { useTranslation } from "react-i18next"
import { Link } from "@react-navigation/native"
import { useContext } from "react"
import { AuthContext } from "../contexts/AuthContext"

export function FormSuccessModal({ visible, setVisible }) {
    const { t, i18n } = useTranslation()
    const appleURL = "https://apps.apple.com/bh/app/consult-me-استشرني/id6468958352"
    const playStoreUrl = "https://play.google.com/store/apps/details?id=com.ConsultMe.ConsultMe"

    return (
        <Modal transparent visible={visible}>
            <View style={styles.outerView}>
                <View style={styles.innerView}>
                    <View style={{ justifyContent: 'space-between', flexDirection: i18n.language == "ar" ? 'row-reverse' : 'row', alignItems: 'center' }}>
                        <Text style={styles.headerText}>Success</Text>
                        <Pressable onPress={() => setVisible(false)}>
                            <AntDesign name="close" size={25} color={"gray"} />
                        </Pressable>
                    </View>
                    <Text style={{ fontFamily: "sans-serif", marginTop: 15 }}>
                        Your form has been successfully sent and a chat conversation has been opened between you and the consultant. To continue your conversation with the lawyer, please download the Consult Me mobile application.
                    </Text>
                    <View style={{ flexDirection: 'row', gap: 5, alignItems: 'center', marginTop: 20, alignSelf: i18n.language == "ar" ? 'flex-end' : 'flex-start' }}>
                        <Pressable onPress={() => Linking.openURL(appleURL, "_blank")}>
                            <Image
                                source={require("../assets/ios-download.png")}
                                style={{ width: 120, height: 40 }}
                            />
                        </Pressable>
                        <Pressable onPress={() => Linking.openURL(playStoreUrl, "_blank")}>
                            <Image
                                source={require("../assets/android-download.png")}
                                style={{ aspectRatio: 2.584, height: 58 }}
                            />
                        </Pressable>
                    </View>
                </View>
            </View>
        </Modal>
    )
}

const styles = StyleSheet.create({
    outerView: {
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        alignItems: 'center',
        justifyContent: 'center'
    },
    innerView: {
        backgroundColor: 'white',
        padding: 25,
        marginHorizontal: 10,
        borderColor: '#b0b0b0',
        borderWidth: 1,
        borderRadius: 10,
        maxWidth: 500
    },
    headerText: {
        fontFamily: "sans-serif",
        fontSize: 22,
        fontWeight: '600'
    }
})