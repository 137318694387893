import { View, StyleSheet } from "react-native"
import { Text } from "../components/Text"
import { HelpBaseView } from "./HelpBase"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet"
import useLang from "../hooks/use-lang"

export function PrivacyPolicy() {
    const { t } = useTranslation()
    const isReady = useLang()

    if (!isReady) {
        return null
    }

    return (
        <HelpBaseView title={"Privacy Policy"}>
            <Helmet>
                <meta property="og:title" content={"Privacy Policy"} />
                <meta property="og:description" content={""} />
                <meta name="description" content={"Required steps to delete all data."} />
            </Helmet>
            <Text style={{ color: 'gray', fontWeight: 'bold', letterSpacing: 0.5, marginBottom: 10 }}>{"Privacy Policy"}</Text>
            <View style={styles.headerText} accessibilityRole="heading">Privacy Policy</View>
            <Text accessibilityRole="heading" accessibilityLevel={2} style={styles.subHeaderText}>1. Introduction</Text>
            <Text style={styles.regularText}>
            Welcome to "Consult Me," an application based in Bahrain, designed to connect clients with lawyers, referred to as "consultants." We respect your privacy and are committed to protecting it. This Privacy Policy explains the type of data we collect, how it is used, and your rights as a user of our application.
            </Text>
            <Text accessibilityRole="heading" accessibilityLevel={2} style={styles.subHeaderText}>2. Data Collection</Text>
            <Text style={styles.regularText}>
                When you use our application, we collect the following data upon sign-up:
            </Text>
            <Text style={styles.regularText}>
                - Name
            </Text>
            <Text style={styles.regularText}>
                - Email address
            </Text>
            <Text style={styles.regularText}>
                - Phone number (optional)
            </Text>
            <Text style={styles.regularText}>
                - GPS location (if allowed)
            </Text>
            <Text accessibilityRole="heading" accessibilityLevel={2} style={styles.subHeaderText}>3. Data Usage & Sharing</Text>
            <Text style={styles.regularText}>
                Your data is primarily used to connect you with consultants. We do not share your data with any third parties, except for allowing a consultant to view your information when you expressly permit them to. While we do integrate payment gateways to facilitate invoices, these gateways do not have access to your stored data beyond transactional needs.
            </Text>
            <Text accessibilityRole="heading" accessibilityLevel={2} style={styles.subHeaderText}>4. User Rights</Text>
            <Text style={styles.regularText}>
            You have the right to request the deletion of your data. Should you wish to do so, you can make a request at https://www.consultmebh.com/help/account-deletion.
            </Text>
        </HelpBaseView>
    )
}

const styles = StyleSheet.create({
    headerText: {
        fontFamily: "sans-serif, NotoSansMedium",
        fontSize: 30,
        fontWeight: '600',
        marginBottom: 16
    },
    subHeaderText: {
        fontFamily: "sans-serif, NotoSansRegular",
        fontSize: 22,
        fontWeight: '500',
        marginTop: 25,
        marginBottom: 12
    },
    regularText: {
        fontSize: 15.5,
        marginVertical: 4,
        letterSpacing: 0.2
    }
})