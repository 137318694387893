import AsyncStorage from "@react-native-async-storage/async-storage"
import { Link, StackActions, useLinkProps, useNavigation, useRoute } from "@react-navigation/native"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Image, Linking, Modal, Pressable, StyleSheet, Text, View, useWindowDimensions } from "react-native"
import AntDesign from 'react-native-vector-icons/AntDesign'
import Icon from 'react-native-vector-icons/Entypo'
import { ResponsiveView } from "./ResponsiveView"
import { MobileDownloadModal } from "./components/MobileDownloadModal"
import { ProfilePicture } from "./components/ProfilePicture"
import { AuthContext } from "./contexts/AuthContext"

function AccountModal({ visible, setVisible }) {
    const { t, i18n } = useTranslation()
    const authContext = useContext(AuthContext)

    const logout = async () => {
        authContext.setLoading(true)
        authContext.setUser({})
        authContext.setToken(null)
        await AsyncStorage.removeItem("token")
        authContext.setLoading(false)
    }

    return (
        <Modal transparent visible={visible}>
            <View style={styles.outerView}>
                <View style={styles.innerView}>
                    <View style={{ justifyContent: 'space-between', flexDirection: i18n.language == "ar" ? 'row-reverse' : 'row', alignItems: 'center', marginBottom: 25 }}>
                        <Text style={styles.headerText}>Account</Text>
                        <Pressable onPress={() => setVisible(false)}>
                            <AntDesign name="close" size={25} color={"gray"} />
                        </Pressable>
                    </View>

                    <Pressable style={{ borderWidth: 1, borderColor: '#dc2626', alignItems: 'center', borderRadius: 5, padding: 5 }} onPress={logout}>
                        <Text style={{ fontFamily: "PoppinsMedium", color: '#dc2626' }}>Logout</Text>
                    </Pressable>
                </View>
            </View>
        </Modal>
    )
}

function HighlightHeaderButton({ title, style, ...props }) {
    const [selected, setSelected] = useState(false)

    return (
        <Pressable style={[styles.highlightHeaderButton, style]}
            onHoverIn={() => setSelected(true)}
            onHoverOut={() => setSelected(false)}
            {...props}>
            <Text style={{ color: 'white', fontSize: 14, fontFamily: "PoppinsSemiBold, NotoSansSemiBold" }}>{title}</Text>
        </Pressable>
    )
}

function HeaderButton({ title, to, ...props }) {
    const [selected, setSelected] = useState(false)

    return (
        <View
            onMouseEnter={() => setSelected(true)}
            onMouseLeave={() => setSelected(false)}
            {...props}>
            <Link to={to} style={[styles.headerButton, selected && { backgroundColor: 'rgba(214, 214, 214, 0.5)', borderRadius: 10 }]}>
                {title}
            </Link>
        </View>
    )
}

function ChangeLanguage({ minimize }) {
    const route = useRoute()
    const [currentLanguage, setCurrentLanguage] = useState(route.path?.startsWith("/ar") ? "ar" : "en")
    const { t, i18n } = useTranslation()

    const getPath = () => {
        if (route.path?.startsWith("/ar")) {
            const p = route.path ? route.path?.replace("/ar", "") : ""
            if (p == "") {
                return "/"
            } else {
                return p
            }
        } else {
            return "/ar" + (route.path ? route.path : "")
        }
    }

    const getLanguageText = () => {
        if (minimize) {
            return currentLanguage == "en" ? "Ar" : "En"
        } else {
            return currentLanguage == "en" ? "Arabic" : "English"
        }
    }

    return (
        <Pressable onPress={() => Linking.openURL(getPath(), "_self")}>
            <Link style={{ padding: 5 }} to={getPath()}>
                <View style={{ alignItems: 'center', marginLeft: 5, gap: 5, flexDirection: 'row' }}>
                    <AntDesign name="earth" size={20} />
                    <Text style={{ fontFamily: "sans-serif", userSelect: 'none', fontSize: 13 }}>{getLanguageText()}</Text>
                </View>
            </Link>
        </Pressable>
    )
}

function UnauthenticatedHeader({ mobileDrawerVisible, setMobileDrawerVisible }) {
    const navigation = useNavigation()
    const { t, i18n } = useTranslation()
    const { height, width } = useWindowDimensions()
    const [modalVisible, setModalVisible] = useState(false)

    return (
        <>
            <MobileDownloadModal visible={modalVisible} setVisible={setModalVisible} />
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Pressable onPress={() => {
                    if (i18n.language == "ar") {
                        Linking.openURL(i18n.language == "ar" ? "/ar" : "", "_self")
                    } else {
                        navigation.push("Home")
                    }
                }}>
                    <Link to={{ screen: i18n.language == "ar" ? 'HomeAr' : 'Home' }}>
                        <Image
                            source={require('./assets/icon.webp')}
                            style={{ width: 60, height: 60, marginRight: width > 880 ? 15 : 5 }}
                        />
                    </Link>
                </Pressable>
                {width > 880 ? (
                    <View style={{ gap: 10, flexDirection: 'row' }}>
                        <HeaderButton title={t("header.consultants")} to={`${i18n.language == "ar" ? "/ar" : ""}/lawyers`} />
                        <HeaderButton title={t("header.contactUs")} to={`${i18n.language == "ar" ? "/ar" : ""}/help/contact`} />
                        <HeaderButton title={t("header.gettingStarted")} to={`${i18n.language == "ar" ? "/ar" : ""}/help/getting-started`} />
                    </View>
                ) : (
                    <View>
                        <ChangeLanguage />
                    </View>
                )}
            </View>
            <View style={{ gap: 20, flexDirection: 'row', alignItems: 'center' }}>
                {width > 880 ? (
                    <>
                        <ChangeLanguage />
                        <HighlightHeaderButton title={"Sign Up"} style={{ marginRight: 5 }} onPress={() => navigation.push("SignUp")} />
                        <HeaderButton title={"Log in"} to={"/login"} />
                    </>
                ) : (
                    <Pressable onPress={() => setMobileDrawerVisible(!mobileDrawerVisible)}>
                        {mobileDrawerVisible ? (
                            <AntDesign name="close" size={25} />
                        ) : (
                            <Icon name="menu" size={25} />
                        )}
                    </Pressable>
                )}
            </View>
        </>
    )
}

function AuthHeader({ mobileDrawerVisible, setMobileDrawerVisible }) {
    const navigation = useNavigation()
    const { t } = useTranslation()
    const authContext = useContext(AuthContext)
    const { height, width } = useWindowDimensions()
    const [accountVisible, setAccountVisible] = useState(false)

    return (
        <>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <AccountModal visible={accountVisible} setVisible={setAccountVisible} />
                {width < 880 ? (
                    <Pressable onPress={() => setMobileDrawerVisible(!mobileDrawerVisible)} style={{ marginRight: 15 }}>
                        {mobileDrawerVisible ? (
                            <AntDesign name="close" size={25} />
                        ) : (
                            <Icon name="menu" size={25} />
                        )}
                    </Pressable>
                ) : null}
                <Link to={{ screen: 'Home' }}>
                    <Image
                        source={require('./assets/icon.webp')}
                        style={{ width: 60, height: 60, marginRight: width > 880 ? 15 : 5 }}
                    />
                </Link>
                {width > 880 ? (
                    <View style={{ gap: 10, flexDirection: 'row' }}>
                        <ChangeLanguage />
                    </View>
                ) : null}
            </View>

            <Pressable style={{ gap: 10, flexDirection: 'row', alignItems: 'center' }} onPress={() => setAccountVisible(true)}>
                {width < 880 ? (
                    <View style={{ gap: 10, flexDirection: 'row' }}>
                        <ChangeLanguage minimize={true} />
                    </View>
                ) : null}
                <ProfilePicture
                    isProfilePicture={authContext.user.profile_picture}
                    userId={authContext.user.id}
                    name={authContext?.name}
                    style={{ width: 40, height: 40, borderRadius: 100 }}
                    textStyle={{ fontSize: 16 }}
                />
                {width > 880 ? (
                    <View style={{ alignItems: 'center', flexDirection: 'row', gap: 15 }}>
                        <Text style={{ fontFamily: "PoppinsMedium", fontSize: 14, color: 'rgb(15, 23, 42)', userSelect: 'none' }}>
                            {authContext?.name}
                        </Text>
                        <Icon name="chevron-thin-down" color={"rgb(15, 23, 42)"} size={12} />
                    </View>
                ) : null}
            </Pressable>
        </>
    )
}

function TopItemText({ label, value }) {
    const navigation = useNavigation()
    const [hovered, setHovered] = useState(false)
    const { t, i18n } = useTranslation()

    const action = () => {
        if (i18n.language == "ar") {
            return () => Linking.openURL(`${i18n.language == "ar" ? "/ar" : ""}/lawyers?category=${value}`, "_self")
        } else {
            return StackActions.push(`Consultants${i18n.language == "ar" ? "Ar" : ""}`, { category: value })
        }
    }

    return (
        <Pressable onHoverIn={() => setHovered(true)} onHoverOut={() => setHovered(false)}>
            <Link to={`${i18n.language == "ar" ? "/ar" : ""}/lawyers?category=${value}`} action={action()} style={{ color: 'white', textDecorationLine: hovered ? 'underline' : 'none', fontFamily: i18n.language == "ar" ? "NotoSansRegular" : null }}>
                {label}
            </Link>
        </Pressable>
    )
}

function Split() {
    return <Text style={{ color: 'white', userSelect: 'none' }}>-</Text>
}

function DrawerItem({ label, onPress, setMobileDrawerVisible }) {
    const handlePress = () => {
        onPress()
        setMobileDrawerVisible(false)
    }

    return (
        <Pressable onPress={handlePress} style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Text style={{ fontFamily: "PoppinsMedium", fontSize: 15 }}>
                {label}
            </Text>
            <AntDesign name="arrowright" size={20} />
        </Pressable>
    )
}

export function Header() {
    const { t, i18n } = useTranslation()
    const navigation = useNavigation()
    const authContext = useContext(AuthContext)
    const { height, width } = useWindowDimensions()
    const [mobileDrawerVisible, setMobileDrawerVisible] = useState(false)

    return (
        <View accessibilityRole="banner">
            <View style={[styles.header, { paddingHorizontal: width > 725 ? 35 : 15 }]}>
                <View style={{ alignItems: 'center' }}>
                    <ResponsiveView style={{ flexDirection: 'row', justifyContent: 'space-between' }} customWidth={1300}>
                        {authContext.token ? (
                            <AuthHeader mobileDrawerVisible={mobileDrawerVisible} setMobileDrawerVisible={setMobileDrawerVisible} />
                        ) : (
                            <UnauthenticatedHeader mobileDrawerVisible={mobileDrawerVisible} setMobileDrawerVisible={setMobileDrawerVisible} />
                        )}
                    </ResponsiveView>
                </View>
                {mobileDrawerVisible ? (
                    <View style={[{ paddingHorizontal: width > 725 ? 35 : 15, gap: 5 }]}>
                        <DrawerItem label="Home" onPress={() => navigation.push("Home")} setMobileDrawerVisible={setMobileDrawerVisible} />
                        {!authContext.token ? (
                            <>
                                <DrawerItem label="Sign Up" onPress={() => navigation.push("SignUp")} setMobileDrawerVisible={setMobileDrawerVisible} />
                                <DrawerItem label="Login" onPress={() => navigation.push("Login")} setMobileDrawerVisible={setMobileDrawerVisible} />
                            </>
                        ) : null}
                        <DrawerItem label="Lawyers" onPress={() => navigation.push("Consultants")} setMobileDrawerVisible={setMobileDrawerVisible} />
                        <DrawerItem label="Contact" onPress={() => navigation.push("Help_Contact")} setMobileDrawerVisible={setMobileDrawerVisible} />
                        <DrawerItem label="Become a consultant" onPress={() => navigation.push("Help_BecomeConsultant")} setMobileDrawerVisible={setMobileDrawerVisible} />
                        <DrawerItem label="Terms of Service" onPress={() => navigation.push("Help_TermsOfService")} setMobileDrawerVisible={setMobileDrawerVisible} />
                    </View>
                ) : null}
            </View>
            {width > 800 ? (
                <View style={styles.strip}>
                    <TopItemText label={t("consultantProfile.lawFields.civilLaw")} value="civil law" />
                    <Split />
                    <TopItemText label={t("consultantProfile.lawFields.criminalLaw")} value="criminal law" />
                    <Split />
                    <TopItemText label={t("consultantProfile.lawFields.familyLaw")} value="family law" />
                    <Split />
                    <TopItemText label={t("consultantProfile.lawFields.bankingLaw")} value="banking law" />
                    <Split />
                    <TopItemText label={t("consultantProfile.lawFields.commercialLaw")} value="commercial law" />
                    <Split />
                    <TopItemText label={t("consultantProfile.lawFields.laborLaw")} value="labor law" />
                    <Split />
                    <TopItemText label={t("consultantProfile.lawFields.privateLaw")} value="private law" />
                    <Split />
                    <TopItemText label={t("consultantProfile.lawFields.publicLaw")} value="public law" />
                </View>
            ) : null}
        </View>
    )
}

const styles = StyleSheet.create({
    header: {
        padding: 5,
        justifyContent: 'space-between',
        backgroundColor: 'white',
    },
    highlightHeaderButton: {
        backgroundColor: '#192655',
        padding: 10,
        borderRadius: 5,
        paddingHorizontal: 20,
        userSelect: 'none'
    },
    headerButton: {
        padding: 10,
        paddingHorizontal: 15,
        userSelect: 'none',
        fontSize: 15,
        fontFamily: "PoppinsMedium, NotoSansMedium",
        color: 'rgb(15, 23, 42)'
    },
    outerView: {
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        alignItems: 'center',
        justifyContent: 'center'
    },
    innerView: {
        backgroundColor: 'white',
        padding: 25,
        marginHorizontal: 10,
        borderColor: '#b0b0b0',
        borderWidth: 1,
        borderRadius: 10,
        maxWidth: 500,
        minWidth: 250
    },
    headerText: {
        fontFamily: "sans-serif",
        fontSize: 22,
        fontWeight: '600'
    },
    strip: {
        backgroundColor: '#192655',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        minHeight: 30
    }
})