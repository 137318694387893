import { View, StyleSheet } from "react-native"
import { Text } from "../components/Text"
import { HelpBaseView } from "./HelpBase"
import { Helmet } from "react-helmet"
import useLang from "../hooks/use-lang"

export function TermsOfService() {
    const isReady = useLang()

    if (!isReady) {
        return null
    }

    return (
        <HelpBaseView title={"Terms of Service"}>
            <Helmet>
                <meta property="og:title" content={"Terms of Service"} />
                <meta property="og:description" content={"Welcome to ConsultMe, a platform that connects you with top-notch consultants and representatives. By using our platform, you agree to the following terms of service:"} />
            </Helmet>
            <Text style={{color: 'gray', fontWeight: 'bold', letterSpacing: 0.5, marginBottom: 10}}>ToS</Text>
            <View style={styles.headerText} accessibilityRole="heading">Terms of Service</View>
            <Text style={styles.regularText}>
                Welcome to ConsultMe, a platform that connects you with top-notch consultants and representatives. By using our platform, you agree to the following terms of service:
            </Text>
            <Text style={styles.regularText}>
                Eligibility: You must be at least 21 years old and capable of forming a binding contract to use our platform. By using our platform, you represent and warrant that you meet these eligibility requirements.
            </Text>
            <Text style={styles.regularText}>
            Use of the Platform: You may use our platform to search for and communicate with lawyers for legal advice and representation. You agree to use our platform only for lawful purposes and in compliance with all applicable laws and regulations.
            </Text>
            <Text style={styles.regularText}>
            User Content: You may submit content, such as reviews and ratings, to our platform. By submitting content, you grant ConsultMe a non-exclusive, royalty-free, perpetual, and irrevocable right to use, reproduce, modify, adapt, publish, translate, distribute, and display such content worldwide in all media.
            </Text>
            <Text style={styles.regularText}>
            Prohibited Conduct: You agree not to engage in any conduct that is unlawful, abusive, threatening, harmful, or otherwise objectionable on our platform. You agree not to interfere with or disrupt our platform or servers or networks connected to our platform.
            </Text>
            <Text style={styles.regularText}>
            Fees and Payments: You may be required to pay fees for legal services provided by lawyers on our platform. ConsultMe charges a fee for using our platform, which will be disclosed to you before you complete a transaction. All fees are non-refundable.
            </Text>
            <Text style={styles.regularText}>
            Disclaimers: ConsultMe does not endorse or recommend any lawyer or legal service provided through our platform. We are not responsible for the conduct, actions, or omissions of any lawyer or user on our platform. We make no warranties or representations about the accuracy or completeness of any content on our platform.
            </Text>
            <Text style={styles.regularText}>
            Limitation of Liability: To the maximum extent permitted by law, ConsultMe and its affiliates, officers, directors, employees, agents, and licensors will not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from your use of our platform.
            </Text>
            <Text style={styles.regularText}>
            Governing Law and Dispute Resolution: These terms of service will be governed by and construed in accordance with the laws of the Kingdom of Bahrain or any jurisdiction which ConsultMe is incorporated. Any disputes arising out of or related to these terms of service will be resolved exclusively by binding arbitration in accordance with the rules and regulations of the Kingdom.
            </Text>
            <Text style={styles.regularText}>
            Changes to Terms of Service: We reserve the right to modify these terms of service at any time without prior notice. Your continued use of our platform after any such modifications constitute your acceptance of the new terms of service.
            </Text>
            <Text style={styles.regularText}>
            Termination: We may terminate your access to our platform at any time without prior notice for any reason. All provisions of these terms of service that by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
            </Text>

            <Text style={styles.regularText}>
            By using ConsultMe, you acknowledge that you have read, understood, and agreed to these terms and conditions. If you do not agree to these terms and conditions, do not use our platform.
            If you have any questions or concerns about these terms of service, please contact us at help@consultmebh.com
            </Text>
        </HelpBaseView>
    )
}

const styles = StyleSheet.create({
    headerText: {
        fontFamily: "sans-serif, NotoSansMedium",
        fontSize: 30,
        fontWeight: '600',
        marginBottom: 16
    },
    subHeaderText: {
        fontFamily: "sans-serif, NotoSansRegular",
        fontSize: 22,
        fontWeight: '500',
        marginTop: 25,
        marginBottom: 12
    },
    regularText: {
        fontSize: 15.5,
        marginVertical: 4,
        letterSpacing: 0.2
    }
})